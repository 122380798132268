import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  GenerateLoanAgreement,
  ESign,
  ESignCheck,
  ESignCheckClearData,
  ESignClearData,
  GenerateLoanAgreementClearData,
  EStamp,
  loanUserDecision,
  Enach,
  EnachStatus,
  InitiateDebitFunction,
  GenerateLoanApplication,
  GetSanctionLetter,
  EnachClearData,
  GetSanctionLetterStatus,
  SaveSanctionLetter,
  ESignAgreement,
  SaveLoanAgreement,
  SaveLoanAgreementInititateClearData,
  SaveLoanAgreementClearData,
  AgreementDataClear,
  GetEsignedLoanApplication,
  GetEsanctionLetter,
  GetELoanAgreement,
  GetELoanAgreementStartDate,
  saveLoanAgreementStartDate,
  modifyRepaymentSchedule,
  sendRepaymentSchedule,
} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgressWithLabel from "../../Common/CircularProgressWithLabel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import Alert from "@mui/material/Alert";
import { Document, Page, pdfjs } from "react-pdf";
import DownloadIcon from "@mui/icons-material/Download";
import { ESignDialogView } from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { Divider, TextField } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import InitateDebit from "./InitateDebit";
import SessionModal from "./../../Common/SessionModel";
const steps = [
  {
    label: "Application Form",
    description: ``,
  },
  {
    label: "Sanction Letter",
    description: "",
  },
  {
    label: "Loan Agreement",
    description: ``,
  },
  {
    label: "eMandate",
    description: ``,
  },
  {
    label: "Confirm & Close",
    description: ``,
  },
];

export default function LoanApprovalFlow(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedLoanId = props.loanID;
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedStartDate, setselectedStartDate] = React.useState("");
  const [eSignInitatedFlag, seteSignInitatedFlag] = React.useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [buttonDisabledAfterClick, setbuttonDisabledAfterClick] =
    React.useState(false);

  const esiginDialog = useSelector(
    (state) => state.BasicDetailsReducers.esiginDialog
  );
  const handleSelectChange = (event) => {
    // Handle the selected value
    setselectedStartDate(event.target.value);
  };
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
    // dispatch(ESignDialogView(false));
  };
  const handleCloseErrorModal1 = () => {
    setShowAlert(false);
    navigate("/login");
  };
  const handleNext = async () => {
    setbuttonDisabledAfterClick(true);
    if (activeStep === 0) {
      setapplicationFlag(false);
      const result = await dispatch(
        GetEsignedLoanApplication(selectedLoanId, handleLogoutButton)
      );
      if (result.data.status_code === 200) {
        dispatch(GetEsanctionLetter(selectedLoanId, handleLogoutButton));
        dispatch(ESignClearData());
        dispatch(ESignCheck(selectedLoanId, handleLogoutButton));
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setNumPages(1);
        setPageNumber(1);
      } else {
        setapplicationFlag(true);
        return;
      }
    }
    if (activeStep === 1) {
      setsanctionFlag(false);
      const result1 = await dispatch(
        GetEsanctionLetter(selectedLoanId, handleLogoutButton)
      );
      if (result1.data.status_code === 200) {
        dispatch(GetELoanAgreement(selectedLoanId, handleLogoutButton));
        dispatch(SaveLoanAgreementInititateClearData());
        dispatch(SaveLoanAgreementClearData());
        dispatch(AgreementDataClear());
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setNumPages(1);
        setPageNumber(1);
        const startDate = await dispatch(
          GetELoanAgreementStartDate(selectedLoanId, handleLogoutButton)
        );
      } else {
        setsanctionFlag(true);
        const startDate = await dispatch(
          GetELoanAgreementStartDate(selectedLoanId, handleLogoutButton)
        );
        return;
      }
      // dispatch(ESignCheck(selectedLoanId));
    }
    if (activeStep === 2) {
      setagreementFlag(false);
      const result2 = await dispatch(
        GetELoanAgreement(selectedLoanId, handleLogoutButton)
      );
      if (result2.data.status_code === 200) {
        dispatch(GetSanctionLetterStatus(selectedLoanId, handleLogoutButton));
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setNumPages(1);
        setPageNumber(1);
      } else {
        setagreementFlag(true);
        return;
      }
    }
    if (activeStep === 3) {
      dispatch(EnachStatus(selectedLoanId, handleLogoutButton));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setNumPages(1);
      setPageNumber(1);
    }

    if (activeStep === 4) {
      navigate("/MyLoan");
      dispatch(ESignDialogView(!esiginDialog));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setNumPages(1);
      setPageNumber(1);
    }
    setbuttonDisabledAfterClick(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setNumPages(1);
    setPageNumber(1);
  };

  const handleGenerateLoanAgreement = () => {
    dispatch(GenerateLoanApplication(selectedLoanId, handleLogoutButton));
  };
  const handleLoanAgreement = async () => {
    try {
      const result = await dispatch(
        saveLoanAgreementStartDate(
          selectedLoanId,
          selectedStartDate,
          handleLogoutButton
        )
      );
      if (result) {
        dispatch(GenerateLoanAgreement(selectedLoanId, handleLogoutButton));
        if (
          StartDateDropDown?.response_data?.actual == "" ||
          StartDateDropDown?.response_data?.actual == null
        ) {
          const Createrepayment = await dispatch(
            modifyRepaymentSchedule(selectedLoanId, handleLogoutButton)
          );
          if (Createrepayment) {
            const Sendrepayment = await dispatch(
              sendRepaymentSchedule(selectedLoanId, handleLogoutButton)
            );
          }
        }
      }
    } catch (e) {}
  };

  const handleEsign = () => {
    if (generateLoanApplicationResponse) {
      if (generateLoanApplicationResponse.status == "success") {
        localStorage.setItem(
          "file_name",
          generateLoanAgreementResponse.blob_url
        );
        dispatch(
          ESign(
            selectedLoanId,
            generateLoanApplicationResponse.base64,
            "eSignApplicationForm",
            handleLogoutButton
          )
        );
      } else {
        alert("Loan application not found");
      }
    }
  };
  // Esign Loan AGreement
  const handleEsignLoanAgreement = () => {
    if (generateLoanAgreementResponse) {
      if (generateLoanAgreementResponse.message == "success") {
        localStorage.setItem("file_name", generateLoanAgreementResponse.data);
        dispatch(
          ESignAgreement(
            selectedLoanId,
            generateLoanAgreementResponse.data,
            handleLogoutButton
          )
        );
      } else {
        alert("Loan application not found");
      }
    }
  };
  //Generate Application form

  const generateLoanApplicationLoading = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanApplicationLoading
  );

  const generateLoanApplicationSuccess = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanApplicationSuccess
  );
  const generateLoanAgreementStartDate = useSelector(
    (state) => state.BasicDetailsReducers.loanAgreementStartDate
  );
  const generateLoanApplicationResponse = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanApplicationResponse
  );

  const generateLoanApplicationFail = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanApplicationFail
  );

  //Generate Agreement API Response

  const generateLoanAgreementLoading = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementLoading
  );

  const generateLoanAgreementSuccess = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementSuccess
  );

  const generateLoanAgreementResponse = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementResponse
  );

  const generateLoanAgreementFail = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementFail
  );

  //Get Sanction Letter API Response

  const getSanctionLetterLoading = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterLoading
  );

  const getSanctionLetterSuccess = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterSuccess
  );

  const getSanctionLetterResponse = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterResponse
  );

  const getSanctionLetterFail = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFail
  );

  //Save Sanction Letter API Response

  const saveSanctionLetterLoading = useSelector(
    (state) => state.BasicDetailsReducers.saveSanctionLetterLoading
  );

  const saveSanctionLetterSuccess = useSelector(
    (state) => state.BasicDetailsReducers.saveSanctionLetterSuccess
  );

  const saveSanctionLetterResponse = useSelector(
    (state) => state.BasicDetailsReducers.saveSanctionLetterResponse
  );

  const saveSanctionLetterFail = useSelector(
    (state) => state.BasicDetailsReducers.saveSanctionLetterFail
  );

  //Get Sanction Letter Status API Response

  const getSanctionLetterFlagLoading = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFlagLoading
  );

  const getSanctionLetterFlagSuccess = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFlagSuccess
  );

  const getSanctionLetterFlagResponse = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFlagResponse
  );

  const getSanctionLetterFlagFail = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFlagFail
  );
  const getapplicationformDocument = useSelector(
    (state) => state.BasicDetailsReducers.loanApplicationFormData
  );
  const getsanctionLetterDocument = useSelector(
    (state) => state.BasicDetailsReducers.loanSanctionLetterData
  );
  const getloanagreementDocument = useSelector(
    (state) => state.BasicDetailsReducers.loanAgreementLetterData
  );
  const [StartDateDropDown, setStartDateDropDown] = React.useState();
  useEffect(() => {
    clearLocalStorage();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const jsonData = generateLoanAgreementStartDate;
        if (
          jsonData.response_data.actual &&
          jsonData.response_data.actual != "" &&
          jsonData.response_data.actual != null
        ) {
          setselectedStartDate(jsonData.response_data.actual);
          // Updating the choice array with the actual value
          const updatedData = {
            ...jsonData,
            response_data: {
              ...jsonData.response_data,
              choice: [
                ...jsonData.response_data.choice,
                jsonData.response_data.actual,
              ],
            },
          };

          setStartDateDropDown(updatedData);
        } else {
          setStartDateDropDown(jsonData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error (e.g., show an error message)
      }
    };
    fetchData();
  }, [generateLoanAgreementStartDate]);
  useEffect(() => {
    clearLocalStorage();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      //dispatch(GetEsignedLoanApplication(selectedLoanId));
      if (getapplicationformDocument.status_code === 200) {
        if (getapplicationformDocument.base64_file) {
          convertBase64toPDF(getapplicationformDocument, "Application Form");
        }
        // dispatch(GetEsanctionLetter(selectedLoanId));
        if (getsanctionLetterDocument.status_code === 200) {
          if (getsanctionLetterDocument.base64_file) {
            convertBase64toPDF(getsanctionLetterDocument, "Sanction Letter");
          }
          // dispatch(GetELoanAgreement(selectedLoanId));
          if (getloanagreementDocument.status_code === 200) {
            if (getloanagreementDocument.base64_file) {
              convertBase64toPDF(getloanagreementDocument, "Loan Agreement");
            }
          }
        }
      }
    };
    fetchData();
  }, [
    getapplicationformDocument,
    getsanctionLetterDocument,
    getloanagreementDocument,
  ]);

  const clearLocalStorage = () => {
    dispatch(ESignCheckClearData());
    dispatch(ESignClearData());
    dispatch(GenerateLoanAgreementClearData());
  };

  const [pdfUrlView, setPdfUrlView] = React.useState();
  const [applicationFormpdfUrlView, setapplicationFormpdfUrlView] =
    React.useState();
  const [sanctionLetterpdfUrlView, setsanctionLetterpdfUrlView] =
    React.useState();
  const [loanAgreementpdfUrlView, setloanAgreementpdfUrlView] =
    React.useState();

  useEffect(() => {
    previewPDF();
  }, [generateLoanApplicationResponse]);

  const previewPDF = () => {
    if (generateLoanApplicationResponse) {
      if (generateLoanApplicationResponse.status == "success") {
        const base64Content = generateLoanApplicationResponse.base64;
        const base64Data = base64Content;
        const pdfData = atob(base64Data);
        const uint8Array = new Uint8Array(pdfData.length);
        for (let i = 0; i < pdfData.length; i++) {
          uint8Array[i] = pdfData.charCodeAt(i);
        }
        const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrlView(pdfUrl);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      }
    }
  };
  const convertBase64toPDF = (document, type) => {
    const base64Content = document.base64_file;
    const base64Data = base64Content;
    const pdfData = atob(base64Data);
    const uint8Array = new Uint8Array(pdfData.length);
    for (let i = 0; i < pdfData.length; i++) {
      uint8Array[i] = pdfData.charCodeAt(i);
    }
    const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    if (type == "Application Form") {
      setapplicationFormpdfUrlView(pdfUrl);
    }
    if (type == "Sanction Letter") {
      setsanctionLetterpdfUrlView(pdfUrl);
    }
    if (type == "Loan Agreement") {
      setloanAgreementpdfUrlView(pdfUrl);
    }

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  };
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPage) => prevPage - 1);
    }
  };

  //--------------------------------------------------------------------------------------
  const handleDownload = () => {
    fetch(pdfUrlView)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Loan_Application_Form.pdf"; // Change the filename if needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };

  const handleDownloadSanction = () => {
    fetch(pdfUrlViewSacntionLetter)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Sanction_Letter.pdf"; // Change the filename if needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };
  const handleDownloadLoanAgreement = () => {
    fetch(pdfUrlViewLoanAgreement)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Loan_Agreement_Form.pdf"; // Change the filename if needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };
  //---------------------------------------------------------------------------------------

  //Esign API Response

  const eSignLoading = useSelector(
    (state) => state.BasicDetailsReducers.eSignLoading
  );
  const eSignResponse = useSelector(
    (state) => state.BasicDetailsReducers.eSignResponse
  );

  const eSignSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eSignSuccess
  );

  const eSignFail = useSelector(
    (state) => state.BasicDetailsReducers.eSignFail
  );
  //------------ E sign Loan Agreement ------------
  const eSignAgreementLoading = useSelector(
    (state) => state.BasicDetailsReducers.eSignAgreementLoading
  );
  const eSignAgreementResponse = useSelector(
    (state) => state.BasicDetailsReducers.eSignAgreementResponse
  );

  const eSignAgreementSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eSignAgreementSuccess
  );

  const eSignAgreementFail = useSelector(
    (state) => state.BasicDetailsReducers.eSignAgreementFail
  );
  const eSignAgreementInitiateLoading = useSelector(
    (state) => state.BasicDetailsReducers.eSignInitiateAgreementLoading
  );
  const eSignAgreementInitiateSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eSignInitiateAgreementSuccess
  );

  const eSignAgreementInitiateFail = useSelector(
    (state) => state.BasicDetailsReducers.eSignInitiateAgreementFail
  );
  //Esign Check

  const eSignCheckLoading = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckLoading
  );

  const eSignCheckSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckSuccess
  );

  const eSignCheckResponse = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckResponse
  );

  const eSignCheckFail = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckFail
  );

  //E Mandate
  //Auth -> it will be either a mobile number or email ->
  //Where user will get a link to initate the eMandate process

  const [auth, setAuth] = React.useState(localStorage.getItem("EmailId"));
  const [applicationFlag, setapplicationFlag] = React.useState(false);
  const [sanctionFlag, setsanctionFlag] = React.useState(false);
  const [agreementFlag, setagreementFlag] = React.useState(false);

  const handleAuthChange = (e) => {
    setAuth(e);
  };

  const handleEmandate = () => {
    dispatch(Enach(selectedLoanId, auth, handleLogoutButton));
  };

  //E-Mandate/Enach - Initate API Response

  const eNachLoading = useSelector(
    (state) => state.BasicDetailsReducers.eNachLoading
  );

  const eNachResponse = useSelector(
    (state) => state.BasicDetailsReducers.eNachResponse
  );

  const eNachSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eNachSuccess
  );

  const eNachFail = useSelector(
    (state) => state.BasicDetailsReducers.eNachFail
  );

  // Enach Status
  const eNachStatusLoading = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusLoading
  );
  const eNachStatusSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusSuccess
  );
  const eNachStatusResponse = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusResponse
  );
  const eNachStatusFail = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusFail
  );

  // Get Sanction Letter

  const handleSanctionLetter = () => {
    dispatch(GetSanctionLetter(selectedLoanId, handleLogoutButton));
  };

  //Function to initiate the Esign For Sanaction Letter

  //FUnction make pdf view for sanction letter

  const [pdfUrlViewSacntionLetter, setPdfUrlViewSacntionLetter] =
    React.useState();
  const [pdfUrlViewLoanAgreement, setpdfUrlViewLoanAgreement] =
    React.useState();
  useEffect(() => {
    previewPDFSactionLetter();
  }, [getSanctionLetterResponse]);
  useEffect(() => {
    previewPDFLoanAgreement();
  }, [generateLoanAgreementResponse]);
  const previewPDFSactionLetter = () => {
    if (getSanctionLetterResponse) {
      if (getSanctionLetterResponse.data) {
        const base64Content = getSanctionLetterResponse.data;
        const base64Data = base64Content;
        const pdfData = atob(base64Data);
        const uint8Array = new Uint8Array(pdfData.length);
        for (let i = 0; i < pdfData.length; i++) {
          uint8Array[i] = pdfData.charCodeAt(i);
        }
        const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrlViewSacntionLetter(pdfUrl);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      }
    }
  };
  const previewPDFLoanAgreement = () => {
    if (generateLoanAgreementResponse) {
      if (generateLoanAgreementResponse.message == "success") {
        const base64Content = generateLoanAgreementResponse.data;
        const base64Data = base64Content;
        const pdfData = atob(base64Data);
        const uint8Array = new Uint8Array(pdfData.length);
        for (let i = 0; i < pdfData.length; i++) {
          uint8Array[i] = pdfData.charCodeAt(i);
        }
        const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setpdfUrlViewLoanAgreement(pdfUrl);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      }
    }
  };

  //---------------------------------------------------------------

  const handleEsignSanctionLetter = () => {
    dispatch(EnachClearData());
    dispatch(
      ESign(
        selectedLoanId,
        getSanctionLetterResponse.data,
        "eSign_sanction_letter",
        handleLogoutButton
      )
    );
  };

  useEffect(() => {
    saveSactionLetter();
  }, [eSignSuccess]);
  useEffect(() => {
    saveLoanAgreementLetter();
  }, [eSignAgreementSuccess]);
  const saveSactionLetter = () => {
    if (Object.keys(getSanctionLetterResponse).length) {
      if (getSanctionLetterResponse.data != "") {
        dispatch(
          SaveSanctionLetter(
            selectedLoanId,
            getSanctionLetterResponse.data,
            handleLogoutButton
          )
        );
      }
    }
  };
  const saveLoanAgreementLetter = () => {
    if (Object.keys(generateLoanAgreementResponse).length) {
      if (generateLoanAgreementResponse.data != "") {
        dispatch(
          SaveLoanAgreement(
            selectedLoanId,
            generateLoanAgreementResponse.data,
            handleLogoutButton
          )
        );
      }
    }
  };
  //--------------------- Status of e -For all id -----------------------

  const statusLoading = useSelector(
    (state) => state.BasicDetailsReducers.statusLoading
  );
  const statusSuccess = useSelector(
    (state) => state.BasicDetailsReducers.statusSuccess
  );
  const statusResponse = useSelector(
    (state) => state.BasicDetailsReducers.statusResponse
  );
  const statusFail = useSelector(
    (state) => state.BasicDetailsReducers.statusFail
  );
  const downloadesigneddocuments = (type) => {
    if (type == "Application Form") {
      window.open(getapplicationformDocument.blob_url, "_self");
    }
    if (type == "Sanction Letter") {
      window.open(getsanctionLetterDocument.blob_url, "_self");
    }
    if (type == "Loan Agreement") {
      window.open(getloanagreementDocument.blob_url, "_self");
    }
  };
  return (
    <>
      <div>
        {/* Your component content */}
        <SessionModal isOpen={showAlert} onClose={handleCloseErrorModal1} />
      </div>
      {statusLoading ? (
        <>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                m: 5,
                mt: 4,
                ml: 0,
              }}
            >
              <CircularProgressWithLabel />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", ml: 5, ml: 0 }}
            >
              <Alert severity="info">
                Please hold for few seconds , we are fetching your details.
              </Alert>
            </Grid>
          </Grid>
        </>
      ) : null}
      {statusResponse.state === "success" && !statusLoading ? (
        <>
          <InitateDebit loanID={selectedLoanId} />
        </>
      ) : null}
      {!statusLoading && statusResponse.state == "failed" ? (
        <>
          <Grid container>
            <Grid item xs={12} md={6} sx={{ p: 5 }}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      optional={
                        index === 4 ? (
                          <Typography variant="caption">Last step</Typography>
                        ) : null
                      }
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      {activeStep === 0 ? (
                        <>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                m: 5,
                                ml: 0,
                                display: "flex",
                                justifyContent: "flex-start",
                                mt: 7,
                                mb: 3,
                              }}
                            >
                              <Button
                                onClick={handleGenerateLoanAgreement}
                                variant="outlined"
                                endIcon={<AssignmentIcon />}
                                sx={{ textTransform: "capitalize" }}
                                disabled={
                                  getapplicationformDocument.status_code === 200
                                }
                              >
                                Generate Application Form
                              </Button>
                            </Grid>
                            {generateLoanApplicationLoading ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 1,
                                    ml: 0,
                                  }}
                                >
                                  <CircularProgressWithLabel />
                                </Grid>
                              </>
                            ) : null}

                            {Object.keys(generateLoanApplicationResponse)
                              .length ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 5, ml: 0 }}
                                >
                                  <Alert severity="success">
                                    Application form is successfully generated.
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}

                            {generateLoanApplicationFail ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 5, ml: 0 }}
                                >
                                  <Alert severity="error">
                                    Sorry! Failed to generate application form
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                            {Object.keys(generateLoanApplicationResponse)
                              .length ? (
                              <>
                                <Grid container>
                                  {generateLoanApplicationResponse.status ==
                                  "success" ? (
                                    <>
                                      <Grid item xs={12} sx={{ pt: 4 }}>
                                        <Divider />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          m: 5,
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          mt: 4,
                                          mb: 3,
                                          ml: 0,
                                        }}
                                      >
                                        <Button
                                          variant="outlined"
                                          onClick={handleEsign}
                                          endIcon={<DocumentScannerIcon />}
                                          sx={{ textTransform: "capitalize" }}
                                        >
                                          Initiate eSign
                                        </Button>
                                      </Grid>
                                    </>
                                  ) : null}
                                </Grid>
                              </>
                            ) : null}
                            {eSignLoading ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 1,
                                  }}
                                >
                                  <CircularProgressWithLabel />
                                </Grid>
                              </>
                            ) : null}
                            {eSignFail ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 0 }}
                                >
                                  <Alert severity="error">
                                    Sorry! Failed to complete eSign
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                            {eSignSuccess ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 2,
                                    ml: 0,
                                  }}
                                >
                                  <Alert severity="info">
                                    To finalize the eSign procedure for your
                                    account, we have sent an email to your
                                    registered email address with all the
                                    necessary instructions and a link to
                                    complete the process. Kindly check your
                                    inbox to find the email from our team.
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </>
                      ) : null}

                      {activeStep === 1 ? (
                        <>
                          <Grid container>
                            {eSignCheckLoading ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 1,
                                  }}
                                >
                                  <CircularProgressWithLabel />
                                </Grid>
                              </>
                            ) : null}
                            {eSignCheckFail ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 2,
                                    mb: 1,
                                    ml: 0,
                                  }}
                                >
                                  <Alert severity="error">
                                    Sorry! Kindly check your inbox to find the
                                    email from our team and complete the eSign.
                                  </Alert>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 2,
                                    mb: 1,
                                    ml: 0,
                                  }}
                                >
                                  <Divider />
                                </Grid>
                              </>
                            ) : null}

                            {Object.keys(eSignCheckResponse).length ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 2,
                                    mb: 1,
                                    ml: 0,
                                  }}
                                >
                                  <Alert severity="info">
                                    {eSignCheckResponse.message}
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                m: 5,
                                ml: 0,
                                display: "flex",
                                justifyContent: "flex-start",
                                mt: 1,
                                mb: 3,
                              }}
                            >
                              <Button
                                onClick={handleSanctionLetter}
                                variant="outlined"
                                disabled={
                                  getsanctionLetterDocument.status_code === 200
                                }
                                endIcon={<AssignmentIcon />}
                                sx={{ textTransform: "capitalize" }}
                              >
                                Generate Sanction Letter
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid container>
                            {getSanctionLetterLoading ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 1,
                                  }}
                                >
                                  <CircularProgressWithLabel />
                                </Grid>
                              </>
                            ) : null}

                            {Object.keys(getSanctionLetterResponse).length ? (
                              <>
                                {getSanctionLetterResponse.data == "" ? (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        m: 5,
                                        mt: 2,
                                        mb: 3,
                                        ml: 0,
                                      }}
                                    >
                                      <Alert severity="error">
                                        Sorry! Failed to generate sanction
                                        letter
                                      </Alert>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        m: 5,
                                        mt: 2,
                                        mb: 3,
                                        ml: 0,
                                      }}
                                    >
                                      <Alert severity="success">
                                        Completed! Please review the sanction
                                        letter
                                      </Alert>
                                    </Grid>
                                  </>
                                )}
                              </>
                            ) : null}
                            {getSanctionLetterFail ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 2,
                                    mb: 3,
                                    ml: 0,
                                  }}
                                >
                                  <Alert severity="error">
                                    Sorry! Faile to generate sanction letter
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                            {Object.keys(getSanctionLetterResponse).length ? (
                              <>
                                <Grid container>
                                  <Grid item xs={12} sx={{ pt: 2 }}>
                                    <Divider />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      m: 5,
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      mt: 4,
                                      mb: 3,
                                      ml: 0,
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      onClick={handleEsignSanctionLetter}
                                      endIcon={<DocumentScannerIcon />}
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      Initiate eSign
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            ) : null}
                            {eSignLoading ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 1,
                                  }}
                                >
                                  <CircularProgressWithLabel />
                                </Grid>
                              </>
                            ) : null}
                            {eSignFail ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 0 }}
                                >
                                  <Alert severity="error">
                                    Sorry! Failed to complete eSign
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                            {eSignSuccess ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 2,
                                    ml: 0,
                                  }}
                                >
                                  <Alert severity="info">
                                    To finalize the eSign procedure for your
                                    account, we have sent an email to your
                                    registered email address with all the
                                    necessary instructions and a link to
                                    complete the process. Kindly check your
                                    inbox to find the email from our team.
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </>
                      ) : null}

                      {activeStep === 2 ? (
                        <>
                          {/* <Grid container>
                            <Grid item xs={12} sx={{ mt: 3 }}>
                              <Alert severity="info">
                                Please skip the step for now and go next
                              </Alert>
                            </Grid>
                          </Grid> */}
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              sx={{
                                m: 2,
                                ml: 0,
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <TextField
                                select
                                onChange={(e) => handleSelectChange(e)}
                                fullWidth
                                label="Select a date"
                                disabled={
                                  StartDateDropDown?.response_data.actual !=
                                    "" &&
                                  StartDateDropDown?.response_data.actual !=
                                    null
                                }
                                value={selectedStartDate}
                                sx={{
                                  "&:hover": {
                                    borderLeft: "5px solid #0093fb",
                                    borderRadius: 1,
                                  },
                                }}
                              >
                                {StartDateDropDown?.response_data.choice.map(
                                  (option, key) => (
                                    <MenuItem key={key} value={option}>
                                      {option}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                m: 2,
                                ml: 0,
                                display: "flex",
                                justifyContent: "flex-start",
                                mt: 2,
                              }}
                            >
                              <Button
                                onClick={handleLoanAgreement}
                                variant="outlined"
                                endIcon={<AssignmentIcon />}
                                disabled={
                                  getloanagreementDocument.status_code ===
                                    200 &&
                                  selectedStartDate != "" &&
                                  selectedStartDate != null
                                }
                                sx={{ textTransform: "capitalize" }}
                              >
                                Generate Loan Agreement
                              </Button>
                            </Grid>
                            {generateLoanAgreementLoading ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 1,
                                    ml: 0,
                                  }}
                                >
                                  <CircularProgressWithLabel />
                                </Grid>
                              </>
                            ) : null}

                            {Object.keys(generateLoanAgreementResponse)
                              .length ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 5, ml: 0 }}
                                >
                                  <Alert severity="success">
                                    Loan Agreement is successfully generated.
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}

                            {generateLoanAgreementFail ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 5, ml: 0 }}
                                >
                                  <Alert severity="error">
                                    Sorry! Failed to generate application form
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                            {Object.keys(generateLoanAgreementResponse)
                              .length ? (
                              <>
                                <Grid container>
                                  {generateLoanAgreementResponse.message ==
                                    "success" &&
                                  generateLoanAgreementResponse.error_message &&
                                  generateLoanAgreementResponse.error_message !=
                                    null &&
                                  generateLoanAgreementResponse.error_message !=
                                    "" ? (
                                    <>
                                      <Alert severity="info">
                                        {
                                          generateLoanAgreementResponse.error_message
                                        }
                                      </Alert>
                                    </>
                                  ) : null}
                                  {generateLoanAgreementResponse.message ==
                                  "success" ? (
                                    <>
                                      <Grid item xs={12} sx={{ pt: 4 }}>
                                        <Divider />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          m: 5,
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          mt: 4,
                                          mb: 3,
                                          ml: 0,
                                        }}
                                      >
                                        <Button
                                          variant="outlined"
                                          onClick={handleEsignLoanAgreement}
                                          endIcon={<DocumentScannerIcon />}
                                          sx={{ textTransform: "capitalize" }}
                                        >
                                          Initiate eSign
                                        </Button>
                                      </Grid>
                                    </>
                                  ) : null}
                                </Grid>
                              </>
                            ) : null}
                            {eSignAgreementInitiateLoading ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 1,
                                  }}
                                >
                                  <CircularProgressWithLabel />
                                </Grid>
                              </>
                            ) : null}
                            {eSignAgreementInitiateFail ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 0 }}
                                >
                                  <Alert severity="error">
                                    Sorry! Failed to complete eSign
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                            {eSignAgreementInitiateSuccess ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 2,
                                    ml: 0,
                                  }}
                                >
                                  <Alert severity="info">
                                    To finalize the eSign procedure for your
                                    account, we have sent an email to your
                                    registered email address with all the
                                    necessary instructions and a link to
                                    complete the process. Kindly check your
                                    inbox to find the email from our team.
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </>
                      ) : null}

                      {activeStep === 3 ? (
                        <>
                          {getSanctionLetterFlagLoading ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 1,
                                }}
                              >
                                <CircularProgressWithLabel />
                              </Grid>
                            </>
                          ) : null}
                          {getSanctionLetterFlagFail ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{ display: "flex", ml: 0 }}
                              >
                                <Alert severity="error">
                                  Sorry! Sanction letter is not singed
                                </Alert>
                              </Grid>
                            </>
                          ) : null}

                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              m: 5,
                              mt: 3,
                              mb: 2,
                              ml: 0,
                            }}
                          >
                            <Typography sx={{ fontWeight: 900 }}>
                              Kindly share your mobile number or email, in order
                              to proceed with your request!
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            sx={{ display: "flex", mt: 2 }}
                          >
                            <Grid item xs={6} sx={{}}>
                              <TextField
                                type="text"
                                size="small"
                                name="auth"
                                fullWidth
                                disabled={true}
                                id="auth"
                                label="Mobile Number / Email Address"
                                value={auth}
                                onChange={(e) =>
                                  handleAuthChange(e.target.value)
                                }
                              ></TextField>
                            </Grid>
                            {statusResponse.initiate_mandate ? (
                              <Grid item xs={6} sx={{}}>
                                <Button
                                  variant="outlined"
                                  onClick={handleEmandate}
                                  disabled={eNachSuccess}
                                  endIcon={<AccountBalanceIcon />}
                                  sx={{ textTransform: "capitalize", p: 0.87 }}
                                >
                                  Initiate Request
                                </Button>
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 3,
                                  mb: 2,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="info">
                                  {statusResponse.error}
                                </Alert>
                              </Grid>
                            )}
                          </Grid>
                          {eNachLoading ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  m: 5,
                                  mt: 3,
                                  ml: 0,
                                }}
                              >
                                <CircularProgressWithLabel />
                              </Grid>
                            </>
                          ) : null}
                          {eNachFail ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 2,
                                  mb: 3,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="error">
                                  {eNachResponse?.message}
                                </Alert>
                              </Grid>
                            </>
                          ) : null}
                          {eNachSuccess ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 2,
                                  mb: 3,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="info">
                                  {eNachResponse
                                    ? eNachResponse.message
                                    : "Sorry! Failed to process the request!"}
                                </Alert>
                              </Grid>
                            </>
                          ) : null}
                        </>
                      ) : null}
                      {activeStep === 4 ? (
                        <>
                          {eNachStatusLoading ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 1,
                                  ml: 0,
                                }}
                              >
                                <CircularProgressWithLabel />
                              </Grid>
                            </>
                          ) : null}
                          {eNachStatusFail ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 2,
                                  mb: 3,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="error">
                                  Sorry! Failed to confirm the eMandate details!
                                </Alert>
                              </Grid>
                            </>
                          ) : null}
                          {eNachStatusSuccess ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 2,
                                  mb: 3,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="info">
                                  {eNachStatusResponse
                                    ? eNachStatusResponse.error
                                    : ""}
                                </Alert>
                              </Grid>
                            </>
                          ) : null}
                        </>
                      ) : null}

                      <Box sx={{ mb: 2 }}>
                        <Grid container>
                          {index === 0 ? (
                            <>
                              <Grid item xs={12} sx={{ m: 5, ml: 1, mr: 1 }}>
                                {applicationFlag ? (
                                  <Alert severity="error">
                                    Document Not Yet Signed
                                  </Alert>
                                ) : null}
                                <Button
                                  variant="contained"
                                  onClick={handleNext}
                                  disabled={buttonDisabledAfterClick}
                                  sx={{
                                    mt: 1,
                                    mr: 1,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {index === steps.length - 1
                                    ? "Finish"
                                    : "Continue"}
                                </Button>
                                <Button
                                  disabled={index === 0}
                                  onClick={handleBack}
                                  sx={{
                                    mt: 1,
                                    mr: 1,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Back
                                </Button>
                              </Grid>
                            </>
                          ) : null}
                          {index === 1 ? (
                            <>
                              <Grid item xs={12} sx={{ m: 5, ml: 1, mr: 1 }}>
                                {sanctionFlag ? (
                                  <Alert severity="error">
                                    Document Not Yet Signed
                                  </Alert>
                                ) : null}
                                <Button
                                  variant="contained"
                                  onClick={handleNext}
                                  disabled={buttonDisabledAfterClick}
                                  sx={{
                                    mt: 1,
                                    mr: 1,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {index === steps.length - 1
                                    ? "Finish"
                                    : "Continue"}
                                </Button>
                                <Button
                                  disabled={index === 0}
                                  onClick={handleBack}
                                  sx={{
                                    mt: 1,
                                    mr: 1,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Back
                                </Button>
                              </Grid>
                            </>
                          ) : null}
                          {index === 2 ? (
                            <>
                              {agreementFlag ? (
                                <Alert severity="error">
                                  Document Not Yet Signed
                                </Alert>
                              ) : null}
                              <Grid item xs={12} sx={{ m: 5, ml: 1, mr: 1 }}>
                                <Button
                                  variant="contained"
                                  disabled={buttonDisabledAfterClick}
                                  onClick={handleNext}
                                  sx={{
                                    mt: 1,
                                    mr: 1,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {index === steps.length - 1
                                    ? "Finish"
                                    : "Continue"}
                                </Button>
                                <Button
                                  disabled={index === 0}
                                  onClick={handleBack}
                                  sx={{
                                    mt: 1,
                                    mr: 1,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Back
                                </Button>
                              </Grid>
                            </>
                          ) : null}
                          {index == 3 ? (
                            <>
                              <Grid item xs={12} sx={{ m: 5, ml: 1, mr: 1 }}>
                                <Button
                                  variant="contained"
                                  onClick={handleNext}
                                  disabled={
                                    !eNachStatusResponse.state ||
                                    eNachStatusResponse.state === "failed"
                                  }
                                  sx={{
                                    mt: 1,
                                    mr: 1,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {index === steps.length - 1
                                    ? "Finish"
                                    : "Continue"}
                                </Button>
                                <Button
                                  disabled={index === 0}
                                  onClick={handleBack}
                                  sx={{
                                    mt: 1,
                                    mr: 1,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Back
                                </Button>
                              </Grid>
                            </>
                          ) : null}
                          {index !== 0 &&
                          index !== 1 &&
                          index !== 2 &&
                          index !== 3 ? (
                            <>
                              <Grid item xs={12} sx={{ m: 5, ml: 1, mr: 1 }}>
                                <Button
                                  variant="contained"
                                  onClick={handleNext}
                                  disabled={
                                    Object.keys(generateLoanApplicationResponse)
                                      .length === 0
                                  }
                                  sx={{
                                    mt: 1,
                                    mr: 1,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {index === steps.length - 1
                                    ? "Finish"
                                    : "Continue"}
                                </Button>
                                <Button
                                  disabled={index === 0}
                                  onClick={handleBack}
                                  sx={{
                                    mt: 1,
                                    mr: 1,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Back
                                </Button>
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            {Object.keys(generateLoanApplicationResponse).length &&
            activeStep === 0 ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ border: "1px solid #000000", mt: 5 }}
                >
                  <div
                    style={{
                      padding: "10px",
                      height: "550px",
                      overflow: "auto",
                    }}
                  >
                    <div style={{ height: "500px" }}>
                      <Document
                        file={pdfUrlView}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{ workerSrc: "/pdf.worker.js" }}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                    {numPages && (
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    )}
                  </div>
                  {numPages && (
                    <Grid container>
                      <Grid item xs={12} md={9}>
                        <div>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handlePrevPage}
                            disabled={pageNumber === 1}
                          >
                            Previous Page
                          </Button>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handleNextPage}
                            disabled={pageNumber === numPages}
                          >
                            Next Page
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          endIcon={<DownloadIcon />}
                          onClick={handleDownload}
                          sx={{ textTransform: "capitalize" }}
                        >
                          Download Application
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : null}
            {Object.keys(getSanctionLetterResponse).length &&
            activeStep === 1 ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ border: "1px solid #000000", mt: 5 }}
                >
                  <div
                    style={{
                      padding: "10px",
                      height: "550px",
                      overflow: "auto",
                    }}
                  >
                    <div style={{ height: "500px" }}>
                      <Document
                        file={pdfUrlViewSacntionLetter}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{ workerSrc: "/pdf.worker.js" }}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                    {numPages && (
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    )}
                  </div>
                  {numPages && (
                    <Grid container>
                      <Grid item xs={12} md={9}>
                        <div>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handlePrevPage}
                            disabled={pageNumber === 1}
                          >
                            Previous Page
                          </Button>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handleNextPage}
                            disabled={pageNumber === numPages}
                          >
                            Next Page
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          endIcon={<DownloadIcon />}
                          onClick={handleDownloadSanction}
                          sx={{ textTransform: "capitalize" }}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : null}
            {Object.keys(generateLoanAgreementResponse).length &&
            activeStep === 2 ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ border: "1px solid #000000", mt: 5 }}
                >
                  <div
                    style={{
                      padding: "10px",
                      height: "550px",
                      overflow: "auto",
                    }}
                  >
                    <div style={{ height: "500px" }}>
                      <Document
                        file={pdfUrlViewLoanAgreement}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{ workerSrc: "/pdf.worker.js" }}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                    {numPages && (
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    )}
                  </div>
                  {numPages && (
                    <Grid container>
                      <Grid item xs={12} md={9}>
                        <div>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handlePrevPage}
                            disabled={pageNumber === 1}
                          >
                            Previous Page
                          </Button>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handleNextPage}
                            disabled={pageNumber === numPages}
                          >
                            Next Page
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          endIcon={<DownloadIcon />}
                          onClick={handleDownloadLoanAgreement}
                          sx={{ textTransform: "capitalize" }}
                        >
                          Download Agreement
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : null}
            {activeStep === 0 &&
            getapplicationformDocument.status_code === 200 ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ border: "1px solid #000000", mt: 5 }}
                >
                  <div
                    style={{
                      padding: "10px",
                      height: "550px",
                      overflow: "auto",
                    }}
                  >
                    <div style={{ height: "500px" }}>
                      <Document
                        file={applicationFormpdfUrlView}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{ workerSrc: "/pdf.worker.js" }}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                    {numPages && (
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    )}
                  </div>
                  {numPages && (
                    <Grid container>
                      <Grid item xs={12} md={9}>
                        <div>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handlePrevPage}
                            disabled={pageNumber === 1}
                          >
                            Previous Page
                          </Button>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handleNextPage}
                            disabled={pageNumber === numPages}
                          >
                            Next Page
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          endIcon={<DownloadIcon />}
                          onClick={() =>
                            downloadesigneddocuments("Application Form")
                          }
                          sx={{ textTransform: "capitalize" }}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : null}
            {activeStep === 1 &&
            getsanctionLetterDocument.status_code === 200 ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ border: "1px solid #000000", mt: 5 }}
                >
                  <div
                    style={{
                      padding: "10px",
                      height: "550px",
                      overflow: "auto",
                    }}
                  >
                    <div style={{ height: "500px" }}>
                      <Document
                        file={sanctionLetterpdfUrlView}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{ workerSrc: "/pdf.worker.js" }}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                    {numPages && (
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    )}
                  </div>
                  {numPages && (
                    <Grid container>
                      <Grid item xs={12} md={9}>
                        <div>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handlePrevPage}
                            disabled={pageNumber === 1}
                          >
                            Previous Page
                          </Button>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handleNextPage}
                            disabled={pageNumber === numPages}
                          >
                            Next Page
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          endIcon={<DownloadIcon />}
                          onClick={() =>
                            downloadesigneddocuments("Sanction Letter")
                          }
                          sx={{ textTransform: "capitalize" }}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : null}
            {activeStep === 2 &&
            getloanagreementDocument.status_code === 200 ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ border: "1px solid #000000", mt: 5 }}
                >
                  <div
                    style={{
                      padding: "10px",
                      height: "550px",
                      overflow: "auto",
                    }}
                  >
                    <div style={{ height: "500px" }}>
                      <Document
                        file={loanAgreementpdfUrlView}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{ workerSrc: "/pdf.worker.js" }}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                    {numPages && (
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    )}
                  </div>
                  {numPages && (
                    <Grid container>
                      <Grid item xs={12} md={9}>
                        <div>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handlePrevPage}
                            disabled={pageNumber === 1}
                          >
                            Previous Page
                          </Button>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handleNextPage}
                            disabled={pageNumber === numPages}
                          >
                            Next Page
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          endIcon={<DownloadIcon />}
                          onClick={() =>
                            downloadesigneddocuments("Loan Agreement")
                          }
                          sx={{ textTransform: "capitalize" }}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : null}
          </Grid>
        </>
      ) : null}
    </>
  );
}
