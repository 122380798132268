import React from "react";
import Grid from "@mui/material/Grid";
import CorestratLogo from "../../Assets/customLogo.png";
import HorizontalNonLinearStepper from "./HorizontalNonLinearStepper";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  const navigateTofirstPage = () => {
    window.location.href = "./BasicDetails";
    localStorage.removeItem("LoanApplicationId");
    //window.location.reload();
  };

  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    navigate("/login");
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "12vh",
          borderBottom: "2px solid #0093fb",
        }}
      >
        <img
          style={{ padding: "20px", cursor: "pointer" }}
          src={CorestratLogo}
          onClick={navigateTofirstPage}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "12vh",
          borderBottom: "2px solid #BEBEBE",
        }}
      ></Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "12vh",
          borderBottom: "2px solid #BEBEBE",
          //   ml:-4
        }}
      >
        <HorizontalNonLinearStepper />
      </Grid>
      <Grid
        item
        xs={12}
        md={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "12vh",
          borderBottom: "2px solid #BEBEBE",
        }}
      >
        <Tooltip title="Logout " sx={{ mt: -1 }}>
          <IconButton
            color="inherit"
            onClick={() => {
              handleLogoutButton();
            }}
          >
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default Index;
