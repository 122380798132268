const TYPES = {
  //------ AADHAR_DRAWER  -----------

  AADHAR_DRAWER: "AADHAR_DRAWER",

  //------ SAF Post API ----------- > SAF: Short Application Form : User Inputs to the API

  SAF_LOADING: "SAF_LOADING",
  SAF_SUCCESS: "SAF_SUCCESS",
  SAF_FAIL: "SAF_FAIL",
  SAF_CLEAR_DATA: "SAF_CLEAR_DATA",

  //-------- Company Details -----------------------------

  COMPANY_DETAILS_LOADING: "COMPANY_DETAILS_LOADING",
  COMPANY_DETAILS_SUCCESS: "COMPANY_DETAILS_SUCCESS",
  COMPANY_DETAILS_FAIL: "COMPANY_DETAILS_FAIL",
  COMPANY_DETAILS_CLEAR_DATA: "COMPANY_DETAILS_CLEAR_DATA",

  //-------- Save Details -----------------------------

  SAVE_DETAILS_LOADING: "SAVE_DETAILS_LOADING",
  SAVE_DETAILS_SUCCESS: "SAVE_DETAILS_SUCCESS",
  SAVE_DETAILS_FAIL: "SAVE_DETAILS_FAIL",
  SAVE_DETAILS_CLEAR_DATA: "SAVE_DETAILS_CLEAR_DATA",

  //Aadhar Verification  - Initiate_Session

  INITIATE_SESSION_AADHAR_LOADING: "INITIATE_SESSION_AADHAR_LOADING",
  INITIATE_SESSION_AADHAR_SUCCESS: "INITIATE_SESSION_AADHAR_SUCCESS",
  INITIATE_SESSION_AADHAR_FAIL: "INITIATE_SESSION_AADHAR_FAIL",
  INITIATE_SESSION_AADHAR_CLEAR_DATA: "INITIATE_SESSION_AADHAR_CLEAR_DATA",

  // Generate OTP

  GEN_OTP_LOADING: "GEN_OTP_LOADING",
  GEN_OTP_SUCCESS: "GEN_OTP_SUCCESS",
  GEN_OTP_FAIL: "GEN_OTP_FAIL",
  GEN_OTP_CLEAR_DATA: "GEN_OTP_CLEAR_DATA",

  // Verify  OTP

  VERIFY_OTP_LOADING: "VERIFY_OTP_LOADING",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAIL: "VERIFY_OTP_FAIL",
  VERIFY_OTP_CLEAR_DATA: "VERIFY_OTP_CLEAR_DATA",

  //Save Aadhaar

  SAVE_AADHAR_LOADING: "SAVE_AADHAR_LOADING",
  SAVE_AADHAR_SUCCESS: "SAVE_AADHAR_SUCCESS",
  SAVE_AADHAR_FAIL: "SAVE_AADHAR_FAIL",
  SAVE_AADHAR_CLEAR_DATA: "SAVE_AADHAR_CLEAR_DATA",

  //------ Confirm All Dilaog  -----------

  CONFIRM_DIALOG: "CONFIRM_DIALOG",

  //Topbar View Steps => Possible Values:  1 -> Step 1, 2 -> Step 2 , 3 -> Step 3, 4-> End

  TOPBAR_VIEW_STEP: "TOPBAR_VIEW_STEP",

  //------   Upload Document API ----------- >

  UPLOAD_DOCUMENT_LOADING: "UPLOAD_DOCUMENT_LOADING",
  UPLOAD_DOCUMENT_SUCCESS: "UPLOAD_DOCUMENT_SUCCESS",
  UPLOAD_DOCUMENT_FAIL: "UPLOAD_DOCUMENT_FAIL",
  UPLOAD_DOCUMENT_CLEAR_DATA: "UPLOAD_DOCUMENT_CLEAR_DATA",

  //------ Upload Dilaog  --------------

  UPLOAD_DIALOG: "UPLOAD_DIALOG",

  //------   Collateral Save ----------- >

  COLLATERAL_SAVE_LOADING: "COLLATERAL_SAVE_LOADING",
  COLLATERAL_SAVE_SUCCESS: "COLLATERAL_SAVE_SUCCESS",
  COLLATERAL_SAVE_FAIL: "COLLATERAL_SAVE_FAIL",
  COLLATERAL_SAVE_CLEAR_DATA: "COLLATERAL_SAVE_CLEAR_DATA",

  //------   Get Loan Details  ----------- >

  GET_LOAN_DETAILS_LOADING: "GET_LOAN_DETAILS_LOADING",
  GET_LOAN_DETAILS_SUCCESS: "GET_LOAN_DETAILS_SUCCESS",
  GET_LOAN_DETAILS_FAIL: "GET_LOAN_DETAILS_FAIL",
  GET_LOAN_DETAILS_CLEAR_DATA: "GET_LOAN_DETAILS_CLEAR_DATA",

  //------   Loan Confirm  ----------- >

  LOAN_CONFIRM_LOADING: "LOAN_CONFIRM__LOADING",
  LOAN_CONFIRM_SUCCESS: "LOAN_CONFIRM_SUCCESS",
  LOAN_CONFIRM_FAIL: "LOAN_CONFIRM_FAIL",
  LOAN_CONFIRM_CLEAR_DATA: "LOAN_CONFIRM_CLEAR_DATA",

  //------   BRE  ----------- >

  BRE_LOADING: "BRE_LOADING",
  BRE_SUCCESS: "BRE_SUCCESS",
  BRE_FAIL: "BRE_FAIL",
  BRE_CLEAR_DATA: "BRE_CLEAR_DATA",

  //------   MY Loan  ----------- >

  MY_LOAN_LOADING: "MY_LOAN_LOADING",
  MY_LOAN_SUCCESS: "MY_LOAN_SUCCESS",
  MY_LOAN_FAIL: "MY_LOAN_FAIL",
  MY_LOAN_CLEAR_DATA: "MY_LOAN_CLEAR_DATA",

  //-------- Company Details -----------------------------

  GUARANTOR_DETAILS_LOADING: "GUARANTOR_DETAILS_LOADING",
  GUARANTOR_DETAILS_SUCCESS: "GUARANTOR_DETAILS_SUCCESS",
  GUARANTOR_DETAILS_FAIL: "GUARANTOR_DETAILS_FAIL",

  //----------------Replayment Details--------------------

  REPAYMENT_DETAILS_LOADING: "REPAYMENT_DETAILS_LOADING",
  REPAYMENT_DETAILS_SUCCESS: "REPAYMENT_DETAILS_SUCCESS",
  REPAYMENT_DETAILS_FAIL: "REPAYMENT_DETAILS_FAIL",

  COMPANY_DATA_LOADED: "COMPANY_DATA_LOADED",

  IDENTIFY_EMPLOYMENT_TYPE: "IDENTIFY_EMPLOYMENT_TYPE",

  IDENTIFY_LOANPRODUCT_TYPE: "IDENTIFY_LOANPRODUCT_TYPE",

  //---------------- E- Sign Dialog Window--------------------

  ESIGN_DIALOG: "ESIGN_DIALOG",

  //------   Generate Loan Agreement  ----------- >

  GENERATE_LOAN_AGREEMENT_LOADING: "GENERATE_LOAN_AGREEMENT_LOADING",
  GENERATE_LOAN_AGREEMENT_SUCCESS: "GENERATE_LOAN_AGREEMENT_SUCCESS",
  GENERATE_LOAN_AGREEMENT_FAIL: "GENERATE_LOAN_AGREEMENT_FAIL",
  GENERATE_LOAN_AGREEMENT_CLEAR_DATA: "GENERATE_LOAN_AGREEMENT_CLEAR_DATA",

  //------   ESign  ----------- >

  ESIGN_LOADING: "ESIGN_LOADING",
  ESIGN_SUCCESS: "ESIGN_SUCCESS",
  ESIGN_FAIL: "ESIGN_FAIL",
  ESIGN_CLEAR_DATA: "ESIGN_CLEAR_DATA",

  //------   ESTAMP  ----------- >

  ESTAMP_LOADING: "ESTAMP_LOADING",
  ESTAMP_SUCCESS: "ESTAMP_SUCCESS",
  ESTAMP_FAIL: "ESTAMP_FAIL",
  ESTAMP_CLEAR_DATA: "ESTAMP_CLEAR_DATA",

  //-------- Esign Check ------------>

  ESIGN_CHECK_LOADING: "ESIGN_CHECK_LOADING",
  ESIGN_CHECK_SUCCESS: "ESIGN_CHECK_SUCCESS",
  ESIGN_CHECK_FAIL: "ESIGN_CHECK_FAIL",
  ESIGN_CHECK_CLEAR_DATA: "ESIGN_CHECK_CLEAR_DATA",

  //-------- Enach Register ------------>

  ENACH_REGISTER_LOADING: "ENACH_REGISTER_LOADING",
  ENACH_REGISTER_SUCCESS: "ENACH_REGISTER_SUCCESS",
  ENACH_REGISTER_FAIL: "ENACH_REGISTER_FAIL",
  ENACH_REGISTER_CLEAR_DATA: "ENACH_REGISTER_CLEAR_DATA",

  //-------- Enach Status ------------>

  ENACH_STATUS_LOADING: "ENACH_STATUS_LOADING",
  ENACH_STATUS_SUCCESS: "ENACH_STATUS_SUCCESS",
  ENACH_STATUS_FAIL: "ENACH_STATUS_FAIL",
  ENACH_STATUS_CLEAR_DATA: "ENACH_STATUS_CLEAR_DATA",

  //----------- initiate Debit  ----------->

  INITIATE_DEBIT_LOADING: "INITIATE_DEBIT_LOADING",
  INITIATE_DEBIT_SUCCESS: "INITIATE_DEBIT_SUCCESS",
  INITIATE_DEBIT_FAIL: "INITIATE_DEBIT_FAIL",
  INITIATE_DEBIT_CLEAR_DATA: "INITIATE_DEBIT_CLEAR_DATA",

  //------   Generate Loan Application  ----------- >

  GENERATE_LOAN_APPLICATION_LOADING: "GENERATE_LOAN_APPLICATION_LOADING",
  GENERATE_LOAN_APPLICATION_SUCCESS: "GENERATE_LOAN_APPLICATION_SUCCESS",
  GENERATE_LOAN_APPLICATION_FAIL: "GENERATE_LOAN_APPLICATION_FAIL",
  GENERATE_LOAN_APPLICATION_CLEAR_DATA: "GENERATE_LOAN_APPLICATION_CLEAR_DATA",

  //------   Get Sanction Letter  ----------- >

  GET_SANCTION_LETTER_LOADING: "GET_SANCTION_LETTER_LOADING",
  GET_SANCTION_LETTER_SUCCESS: "GET_SANCTION_LETTER_SUCCESS",
  GET_SANCTION_LETTER_FAIL: "GET_SANCTION_LETTER_FAIL",
  GET_SANCTION_LETTER_CLEAR_DATA: "GET_SANCTION_LETTER_CLEAR_DATA",

  //------   Save Sanction Letter  ----------- >

  SAVE_SANCTION_LETTER_LOADING: "SAVE_SANCTION_LETTER_LOADING",
  SAVE_SANCTION_LETTER_SUCCESS: "SAVE_SANCTION_LETTER_SUCCESS",
  SAVE_SANCTION_LETTER_FAIL: "SAVE_SANCTION_LETTER_FAIL",
  SAVE_SANCTION_LETTER_CLEAR_DATA: "SAVE_SANCTION_LETTER_CLEAR_DATA",

  //------   Get Sanction Letter Status  ----------- >

  GET_SANCTION_STATUS_LOADING: "GET_SANCTION_STATUS_LOADING",
  GET_SANCTION_STATUS_SUCCESS: "GET_SANCTION_STATUS_SUCCESS",
  GET_SANCTION_STATUS_FAIL: "GET_SANCTION_STATUS_FAIL",
  GET_SANCTION_STATUS_CLEAR_DATA: "GET_SANCTION_STATUS_CLEAR_DATA",

  //------   Enach  Status --for all the loan ids  ----------- >

  STATUS_LOADING: "STATUS_LOADING",
  STATUS_SUCCESS: "STATUS_SUCCESS",
  STATUS_FAIL: "STATUS_FAIL",
  STATUS_CLEAR_DATA: "STATUS_CLEAR_DATA",

  UPLOAD_DIALOG: "UPLOAD_DIALOG",

  TENURE: "TENURE",
  MARITALSTATUS: "MARITALSTATUS",
  BRANCHNAME: "BRANCHNAME",
  BANKNAMES: "BANKNAMES",

  ESIGNAGREEMENT_LOADING: "ESIGNAGREEMENT_LOADING",
  ESIGNAGREEMENT_SUCCESS: "ESIGNAGREEMENT_SUCCESS",
  ESIGNAGREEMENT_FAIL: "ESIGNAGREEMENT_FAIL",
  ESIGNAGREEMENT_CLEAR_DATA: "ESIGNAGREEMENT_CLEAR_DATA",

  ESIGNINITIATEAGREEMENT_LOADING: "ESIGNINITIATEAGREEMENT_LOADING",
  ESIGNINITIATEAGREEMENT_SUCCESS: "ESIGNINITIATEAGREEMENT_SUCCESS",
  ESIGNINITIATEAGREEMENT_FAIL: "ESIGNINITIATEAGREEMENT_FAIL",
  ESIGNINITIATEAGREEMENT_CLEAR_DATA: "ESIGNINITIATEAGREEMENT_CLEAR_DATA",

  //-------------- SIGNED DOCUMENTS DATA ---------------------//
  LOAN_APPLICATION_DOWNLOAD_DATA: "LOAN_APPLICATION_DOWNLOAD_DATA",
  SANCTION_LETTER_DOWNLOAD_DATA: "SANCTION_LETTER_DOWNLOAD_DATA",
  LOAN_AGREEMENT_DOWNLOAD_DATA: "LOAN_AGREEMENT_DOWNLOAD_DATA",
  LOAN_AGREEMENT_START_DATE: "LOAN_AGREEMENT_START_DATE",

  // GenInsight User Query
  USER_QUERY_LOADING: "USER_QUERY_LOADING",
  USER_QUERY_SUCCESS: "USER_QUERY_SUCCESS",
  USER_QUERY_FAIL: "USER_QUERY_FAIL",
  USER_QUERY_NETWORK_ERROR: "USER_QUERY_NETWORK_ERROR",
  USER_QUERY_CLEAR_DATA: "USER_QUERY_CLEAR_DATA",

  // GenInsight chat messages
  CHAT_MESSAGES_LOADING: "CHAT_MESSAGES_LOADING",
  CHAT_MESSAGES_SUCCESS: "CHAT_MESSAGES_SUCCESS",
  CHAT_MESSAGES_FAIL: "CHAT_MESSAGES_FAIL",
  CHAT_MESSAGES_NETWORK_ERROR: "CHAT_MESSAGES_NETWORK_ERROR",
  CHAT_MESSAGES_CLEAR_DATA: "CHAT_MESSAGES_CLEAR_DATA",

  //Verify Mobile OTP
  VERIFY_OTP_MOBILE_LOADING: "VERIFY_OTP_MOBILE_LOADING",
  VERIFY_OTP_MOBILE_SUCCESS: "VERIFY_OTP_MOBILE_SUCCESS",
  VERIFY_OTP_MOBILE_FAIL: "VERIFY_OTP_MOBILE_FAIL",

  // Verify PAN
  VERIFY_PAN_SUCCESS: "VERIFY_PAN_SUCCESS",
  VERIFY_PAN_FAIL: "VERIFY_PAN_FAIL",
  VERIFY_PAN_CLEAR: "VERIFY_PAN_CLEAR",

  NAVIGATE_FROM_DIGITAL_LOANS: "NAVIGATE_FROM_DIGITAL_LOANS",
  NAVIGATE_FROM_LOGIN: "NAVIGATE_FROM_LOGIN",

  VERIFY_OTP_EMAIL_LOADING: "VERIFY_OTP_EMAIL_LOADING",
  VERIFY_OTP_EMAIL_SUCCESS: "VERIFY_OTP_EMAIL_SUCCESS",
  VERIFY_OTP_EMAIL_FAIL: "VERIFY_OTP_EMAIL_FAIL",

  AADHAR_DRAWER_COBORROWER: "AADHAR_DRAWER_COBORROWER",

  VERIFY_OTP_COBORROWER_SUCCESS: "VERIFY_OTP_COBORROWER_SUCCESS",
  VERIFY_OTP_COBORROWER_FAIL: "VERIFY_OTP_COBORROWER_FAIL",
  VERIFY_OTP_COBORROWER_LOADING: "VERIFY_OTP_COBORROWER_LOADING",
  VERIFY_PAN_SUCCESS_COBORROWER: "VERIFY_PAN_SUCCESS_COBORROWER",

  VERIFY_PAN_FAIL_COBORROWER: "VERIFY_PAN_FAIL_COBORROWER",
  VERIFY_PAN_CLEAR_COBORROWER: "VERIFY_PAN_CLEAR_COBORROWER",

  VERIFY_OTP_EMAIL_COBORROWER_LOADING: "VERIFY_OTP_EMAIL_COBORROWER_LOADING",
  VERIFY_OTP_EMAIL_COBORROWER_SUCCESS: "VERIFY_OTP_EMAIL_COBORROWER_SUCCESS",
  VERIFY_OTP_EMAIL_COBORROWER_FAIL: "VERIFY_OTP_EMAIL_COBORROWER_FAIL",

  VERIFY_OTP_COBORROWER_CLEAR_DATA: "VERIFY_OTP_COBORROWER_CLEAR_DATA",
};
export default TYPES;
