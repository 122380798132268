import React from "react";
import { Typography, Grid } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import RoomIcon from "@mui/icons-material/Room";
import "./footer.css"; // Make sure to replace this with your actual CSS file
import pic1 from "./../../Assets/googlemapGosree.png";
const Footer = () => {
  return (
    <footer className="footer" style={{ backgroundColor: "#ffffff" }}>
      <Grid container spacing={2}>
        {/* Left side with phone icon and contact information */}
        <Grid item xs={12} md={7} lg={7}>
          <div className="contact-info">
            <PhoneIcon fontSize="large" style={{ fill: "#0093fb" }} />
            <Typography className="contact-us">
              <b>Contact Us</b>
            </Typography>
            <Typography className="address">+91 8777887665</Typography>
          </div>
        </Grid>

        {/* Right side with locate us, map, and address */}
        <Grid item xs={12} md={2} lg={2}>
          <div className="locate-info">
            <Typography className="locate-us">
              <b>Locate us</b>
            </Typography>
            {/* Add your map component or map image here */}
            <img style={{ cursor: "pointer", height: "12vh" }} src={pic1} />
          </div>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <div className="locate-info">
            <Typography className="locate-us">
              <b>Address</b>
            </Typography>
            <Typography className="address">Corestrat Finance</Typography>
            <Typography className="address">
              Floor 3 - Cheryl Hill Park, Vallathol Junction, Seaport - Airport
              Rd Kakkanad, Kochi, Kerala 682021
            </Typography>
          </div>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
