import React, { useState } from "react";
import "./Header.css"; // Create a separate CSS file for styling
import logo from "../../Assets/customLogo.png";

const Header = () => {
  const [showContactPopup, setShowContactPopup] = useState(false);

  const handleContactHover = () => {
    setShowContactPopup(true);
  };

  const handleContactLeave = () => {
    setShowContactPopup(false);
  };

  return (
    <div style={{ paddingTop: "1%", background: "#ffffff" }}>
      <div className="header">
        <div className="logo-container">
          <img src={logo} style={{ width: "27%" }} />
          {/* <div className="logo">Corestrat Finance Limited</div> */}
        </div>
        <div
          className="contact"
          onMouseEnter={handleContactHover}
          onMouseLeave={handleContactLeave}
        >
          Contact Us
          {showContactPopup && (
            <div className="contact-popup">
              {" "}
              Feel free to reach out to us at DlaSupport@corestrat.ai <br />{" "}
              connect with us via chat at 8129767770.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
