import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import * as blazeface from '@tensorflow-models/blazeface';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import * as tf from '@tensorflow/tfjs';
import { sendkycToAPI, videoKycStart, videoKycValidateFrame, videoKycLivelinessCheck } from '../BasicDetails/BasicDetailsActions/BasicDetailsActions';
import { useDispatch,useSelector } from 'react-redux';

const Container = styled('div')({
    position: 'relative',
  });
  
const Canvas = styled('canvas')({
    position: 'absolute',
    top: 0,
    left: 0,
  });


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const WebcamWithFaceDetection = ({ onPhotoTaken,loanId }) => {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [faceVisible, setFaceVisible] = useState(false);
  const [isWebcamOn, setIsWebcamOn] = useState(false);
  const [webcamWidth, setWebcamWidth] = useState(null);
  const [webcamHeight, setWebcamHeight] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [pictureConfirm, setPictureConfirm] = useState(false);
  const [modelLoaded, setModelLoaded] = useState(false);
  const [faceDetectionInterval, setFaceDetectionInterval] = useState(null);
  const [list, setList] = useState([]);
  const [faceCoordinates, setFaceCoordinates] = useState(null);
  const dispatch = useDispatch();

  const [recordStartTime, setRecordStartTime] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [framesCaptured, setFramesCaptured] = useState(0);
  const [sessionId, setSessionId] = useState(null);
  const [isAlive, setIsAlive] = useState(null);

  useEffect(() => {
    const loadModel = async () => {
      try {
        await tf.ready(); // Wait for TensorFlow to be ready
        const model = await blazeface.load();
        setModelLoaded(true);
        const video = webcamRef.current.video; // Video element from the webcam
        const { videoWidth, videoHeight } = video;
        setWebcamWidth(videoWidth);
        setWebcamHeight(videoHeight);
        const detectFaces = async () => {
          const predictions = await model.estimateFaces(video); // Estimate faces in the video stream
          setFaceVisible(predictions.length > 0); // Update state based on face detection results
          if (predictions.length > 0) {
            // Set the coordinates of the detected face
            setFaceCoordinates(predictions[0]);
          } else {
            setFaceCoordinates(null); // Reset face coordinates if no face is detected
          }
        };
        // Start face detection loop
        const intervalId = setInterval(detectFaces, 200); // Adjust interval as needed
        // Cleanup function to stop face detection when the component unmounts or webcam is stopped
        setFaceDetectionInterval(intervalId);
        return () => clearInterval(intervalId);
      } catch (error) {
        console.error('Error loading model:', error);
      }
    };

    if (isWebcamOn) {
      loadModel();
    } else {
      // If webcam is turned off, stop face detection
      setModelLoaded(false);
    }
  }, [isWebcamOn]);

  useEffect(() => {
    const recordFrames = async () => {
      if (Date.now() - recordStartTime > 5000) {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        setIsAlive("Recording finished. Press to validate");

        setIsRecording(false);
        setRecordStartTime(null);
        setFramesCaptured(0);
      }
      if (Math.floor((Date.now() - recordStartTime) / 300) > framesCaptured){
        const frameSrc = webcamRef.current.getScreenshot()?.replace(/^data:image\/\w+;base64,/, '');
        setFramesCaptured(framesCaptured + 1);

        videoKycValidateFrame(sessionId, frameSrc);
      }
    }
    if (isRecording){
      const recordingInterval = setInterval(recordFrames, 200);
      return () => clearInterval(recordingInterval);
    }
    else{
      setIsRecording(false);
      setRecordStartTime(null);
      setFramesCaptured(0);
    }
  }, [isRecording, framesCaptured])

  useEffect(() => {   
    if (faceCoordinates && canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); // Clear the canvas before drawing

    if (faceCoordinates) {
      const { topLeft, bottomRight } = faceCoordinates;
      ctx.beginPath();
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'green';
      ctx.rect(topLeft[0], topLeft[1], bottomRight[0] - topLeft[0], bottomRight[1] - topLeft[1]);
      ctx.stroke();
    }
    }
  }, [faceCoordinates]);
  


  useEffect(() => {
    if (list.length > 0) {
      const loan_id= loanId;
      dispatch(
        sendkycToAPI(
          loan_id,
          list 
        )
      );
    }
  }, [list, loanId, dispatch]);

  const handleStartClick = () => {
    setIsWebcamOn(true);
  };

  const handleConfirm = async () => {
    const livelinessCheck = await videoKycLivelinessCheck(sessionId);
    
    if (livelinessCheck){
      clearInterval(faceDetectionInterval);
      setIsWebcamOn(false);
      setPictureConfirm(true);
      onPhotoTaken(true);
      try {
        const image = await convertToBase64(imgSrc);
        setList(prevList => [...prevList, image]);
      } catch (error) {
        console.error('Error processing image:', error);
      }
    }
    else {
      setIsAlive("ERROR: Video KYC failed. Please blink twice slowly when the recording starts.");
    }
    
  };
  const kycSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifykycSuccess
  );

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc); // Set the captured image directly
  };

  const convertToBase64 = (imageSrc) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
  
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        let base64Data = canvas.toDataURL('image/jpeg');
        // Remove the prefix from the base64 data
        base64Data = base64Data.replace(/^data:image\/\w+;base64,/, '');
        resolve(base64Data);
      };
  
      img.onerror = (error) => {
        reject(error);
      };
  
      img.src = imageSrc;
    });
  };

  const handleRecordStart = async (event) => {

    const ref_id = await videoKycStart();
    console.log(ref_id);
    setSessionId(ref_id);

    setRecordStartTime(Date.now());
    setIsRecording(true);
  }

  return (
    <>
    {!pictureConfirm && kycSuccess ? (
      <Box sx={{ flexGrow: 1 , position: 'relative'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Item>
              {isWebcamOn ? (
                <>
                <div style={{ position: 'relative' }}>
                <Webcam
                      ref={webcamRef}
                      onUserMedia={() => {
                        const { video } = webcamRef.current;
                        setWebcamWidth(video.videoWidth);
                        setWebcamHeight(video.videoHeight);
                      }}
                      screenshotFormat="image/jpeg"
                      style={{ width: '100%', height: '100%', position: 'relative', zIndex:0 }}
                />
                    {faceVisible && (
                      <Canvas
                      ref={canvasRef}
                      width={webcamWidth}
                      height={webcamHeight}
                      style={{
                        width: '100%',  // Ensure the canvas fills its container
                        height: '100%', // Ensure the canvas fills its container
                        zIndex: 1
                      }}
                    />

                    )}
                </div>
                </>
              ) : (
                <div>
                  <p style={{ textAlign: 'center' }}>1. Please Remove your Glasses</p>
                  <p style={{ textAlign: "center" }}>2. Please blink twice slowly when the recording starts</p>
                  <Button variant="contained" onClick={handleStartClick} id="open_webcam_button">
                  Start Webcam
                  </Button>
                </div>
          )}
            </Item>
          </Grid>
          <Grid item xs={12} md={5}>
            <Item>
              {faceVisible ? (
                <div>
                  <p style={{ textAlign: 'center' }}>Face is visible</p>
                  {/* <Button variant="contained" onClick={handleCapture} id="take_picture_button">
                    Take picture
                  </Button> */}
                  <Button variant="contained" disabled={isRecording} onClick={handleRecordStart} id="video-kyc-start-button">
                    {!isRecording && "Start Recording"}
                    {isRecording && "Recording ..."}
                  </Button>
                </div>
              ) : (
                // <div>
                //   <p style={{ textAlign: 'center' }}>No face detected</p>
                //   <Button variant="disabled">Take picture</Button>
                // </div>
                <div>
                  <p style={{ textAlign: 'center' }}>No face detected</p>
                  <Button variant="disabled">Start Recording</Button>
                </div>
              )}
            </Item>
          </Grid>
          {imgSrc && (
            <>
            <Grid item xs={12} md={5}>
              <Item>
                <img src={imgSrc} alt="Captured" style={{ width: '100%', height: '100%' }} />
              </Item>
            </Grid>
            <Grid item xs={12} md={5} justifyContent={"center"}>
            <Item>
              <div>
                <p style={{ textAlign: "center", color: isAlive?.startsWith("ERROR") ? "red" : "inherit" }}>{isAlive}</p>
                {pictureConfirm ? 
                <Button variant="disabled">Recording Saved</Button> : 
                <Button variant="contained" onClick={handleConfirm} id="confirm_picture_button">Confirm Recording</Button>}
              </div>
            </Item>
          </Grid>
          </>
          )}
        </Grid>
      </Box> ) : (
        <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Item>
              <div><p>KYC confirmed</p></div>
            </Item>
          </Grid>
          </Grid></Box>
      )}
    </>
  );
};

export default WebcamWithFaceDetection; 
