// Header.js

import React from "react";
import { Grid, Typography } from "@mui/material";
import "./Header1.css";
import Divider from "@mui/material/Divider";
import { Widgets } from "@mui/icons-material";
import pic1 from "./../../Assets/customLogo.png";

const Header1 = () => {
  const gridData = [
    {
      title: "Approved NBFC by",
      value: "RBI",
    },
    {
      title: "Years In Business",
      value: "10+",
    },
    {
      title: "Loans disbursed so far",
      value: "500 cr",
    },
    {
      title: "Customer Satisfaction",
      value: "99%",
    },
    // Add more items as needed
  ];
  return (
    <header className="header1">
      <div style={{ width: "100%" }}>
        <div className="logo">
          {" "}
          <img
            className="logo-image"
            style={{ cursor: "pointer" }}
            src={pic1}
          />
        </div>
        <div className="title">
          Welcome to <b>Corestrat Finance</b>
        </div>
        <div className="subtitle">
          <b>Humanizing the Experience of Finance</b>
        </div>
      </div>
      <Grid container sx={{ mt: 2, mb: 2 }}>
        <Divider orientation="horizontal" flexItem className="header-divider" />
      </Grid>
      <div className="grid-container">
        {gridData.map((item, index) => (
          <div key={index}>
            <div className="grid-item">
              <div className="grid-item-key">{item.title}</div>
              <div className="grid-item-value">
                <b>{item.value}</b>
              </div>
            </div>
          </div>
        ))}
      </div>
    </header>
  );
};

export default Header1;
