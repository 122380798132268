import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  GenerateLoanAgreement,
  ESign,
  ESignCheck,
  ESignCheckClearData,
  ESignClearData,
  GenerateLoanAgreementClearData,
  EStamp,
  loanUserDecision,
  Enach,
  EnachStatus,
  InitiateDebitFunction,
  GenerateLoanApplication,
  GetSanctionLetter,
  EnachClearData,
  GetSanctionLetterStatus,
  SaveSanctionLetter,
  ESignAgreement,
  SaveLoanAgreement,
  SaveLoanAgreementInititateClearData,
  SaveLoanAgreementClearData,
  AgreementDataClear,
  GetEsignedLoanApplication,
  GetEsanctionLetter,
  GetELoanAgreement,
  GetELoanAgreementStartDate,
  saveLoanAgreementStartDate,
  modifyRepaymentSchedule,
  sendRepaymentSchedule,
  GenerateLoanApplicationDigitalLoans,
  ESignDigitalLoans,
  GenerateLoanAgreementDigitalLoan,
  ESignAgreementDigitalLoans,
} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgressWithLabel from "../../Common/CircularProgressWithLabel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import Alert from "@mui/material/Alert";
import { Document, Page, pdfjs } from "react-pdf";
import DownloadIcon from "@mui/icons-material/Download";
import { ESignDialogView } from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { Divider, TextField } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import InitateDebit from "./InitateDebit";
import SessionModal from "./../../Common/SessionModel";
import { Dialog } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
const steps = [
  {
    label: "Application Form",
    description:
      "A formal application, requesting a loan... Covering the basic details of the applicant / loan requestor",
  },
  {
    label: "Loan Agreement",
    description:
      "An agreement with a legal binding between the financier and the borrower (applicant) covering all details of the loan. To be signed by both parties and requsite stamp duty to be paid, for the agreement to be legal and valid in the court of law.",
  },
  {
    label: "eMandate",
    description:
      "This is an authorization from the account holder - to his bank, authorising them to debit his account for a certain amount regularly and credit the specified receiver's bank account",
  },
  {
    label: "Disburse",
    description: "",
  },
];

export default function LoanApprovalFlowDigitalLoans(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedLoanId = props.loanID;
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedStartDate, setselectedStartDate] = React.useState("");
  const [eSignInitatedFlag, seteSignInitatedFlag] = React.useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [buttonDisabledAfterClick, setbuttonDisabledAfterClick] =
    React.useState(false);

  const [showLoanDisbursedPopup, setShowLoanDisbursedPopup] = React.useState(false);

  const esiginDialog = useSelector(
    (state) => state.BasicDetailsReducers.esiginDialog
  );
  const handleSelectChange = (event) => {
    // Handle the selected value
    setselectedStartDate(event.target.value);
  };
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
    // dispatch(ESignDialogView(false));
  };
  const handleCloseErrorModal1 = () => {
    setShowAlert(false);
    navigate("/login");
  };
  const handleNext = async () => {
    setbuttonDisabledAfterClick(true);
    navigate("/MyLoan");
    dispatch(ESignDialogView(!esiginDialog));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setNumPages(1);
    setPageNumber(1);
    setbuttonDisabledAfterClick(false);
  };

  const disburseLoan = () => {
    setShowLoanDisbursedPopup(true);
  }

  const handleLoanDisbursedPopupClose = () => {
    setShowLoanDisbursedPopup(false);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setNumPages(1);
    setPageNumber(1);
  };

  const handleGenerateLoanAgreement = () => {
    dispatch(GenerateLoanApplication(selectedLoanId, handleLogoutButton));
  };
  const handleLoanAgreement = async () => {
    try {
      const result = await dispatch(
        saveLoanAgreementStartDate(
          selectedLoanId,
          selectedStartDate,
          handleLogoutButton
        )
      );
      if (result) {
        dispatch(
          GenerateLoanAgreementDigitalLoan(selectedLoanId, handleLogoutButton)
        );
        if (
          StartDateDropDown?.response_data?.actual == "" ||
          StartDateDropDown?.response_data?.actual == null
        ) {
          const Createrepayment = await dispatch(
            modifyRepaymentSchedule(selectedLoanId, handleLogoutButton)
          );
          if (Createrepayment) {
            const Sendrepayment = await dispatch(
              sendRepaymentSchedule(selectedLoanId, handleLogoutButton)
            );
          }
        }
      }
    } catch (e) {}
  };

  const handleEsign = () => {
    if (generateLoanApplicationResponse) {
      dispatch(
        ESignDigitalLoans(
          selectedLoanId,
          generateLoanApplicationResponse.file_base64_str,
          handleLogoutButton
        )
      );
    }
  };
  // Esign Loan AGreement
  const handleEsignLoanAgreement = () => {
    if (generateLoanAgreementResponse) {
      if (generateLoanAgreementResponse.is_signed == false) {
        dispatch(
          ESignAgreementDigitalLoans(
            selectedLoanId,
            generateLoanAgreementResponse.file_base64_str,
            handleLogoutButton
          )
        );
      } else {
        alert("Loan application not found");
      }
    }
  };
  //Generate Application form

  const generateLoanApplicationLoading = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanApplicationLoading
  );

  const generateLoanApplicationSuccess = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanApplicationSuccess
  );
  const generateLoanAgreementStartDate = useSelector(
    (state) => state.BasicDetailsReducers.loanAgreementStartDate
  );
  const generateLoanApplicationResponse = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanApplicationResponse
  );

  const generateLoanApplicationFail = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanApplicationFail
  );

  //Generate Agreement API Response

  const generateLoanAgreementLoading = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementLoading
  );

  const generateLoanAgreementSuccess = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementSuccess
  );

  const generateLoanAgreementResponse = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementResponse
  );

  const generateLoanAgreementFail = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementFail
  );

  //Get Sanction Letter API Response

  const getSanctionLetterLoading = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterLoading
  );

  const getSanctionLetterSuccess = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterSuccess
  );

  const getSanctionLetterResponse = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterResponse
  );

  const getSanctionLetterFail = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFail
  );

  //Save Sanction Letter API Response

  const saveSanctionLetterLoading = useSelector(
    (state) => state.BasicDetailsReducers.saveSanctionLetterLoading
  );

  const saveSanctionLetterSuccess = useSelector(
    (state) => state.BasicDetailsReducers.saveSanctionLetterSuccess
  );

  const saveSanctionLetterResponse = useSelector(
    (state) => state.BasicDetailsReducers.saveSanctionLetterResponse
  );

  const saveSanctionLetterFail = useSelector(
    (state) => state.BasicDetailsReducers.saveSanctionLetterFail
  );

  //Get Sanction Letter Status API Response

  const getSanctionLetterFlagLoading = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFlagLoading
  );

  const getSanctionLetterFlagSuccess = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFlagSuccess
  );

  const getSanctionLetterFlagResponse = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFlagResponse
  );

  const getSanctionLetterFlagFail = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFlagFail
  );
  const getapplicationformDocument = useSelector(
    (state) => state.BasicDetailsReducers.loanApplicationFormData
  );
  const getsanctionLetterDocument = useSelector(
    (state) => state.BasicDetailsReducers.loanSanctionLetterData
  );
  const getloanagreementDocument = useSelector(
    (state) => state.BasicDetailsReducers.loanAgreementLetterData
  );
  const [StartDateDropDown, setStartDateDropDown] = React.useState();
  useEffect(() => {
    clearLocalStorage();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const jsonData = generateLoanAgreementStartDate;
        if (
          jsonData.response_data.actual &&
          jsonData.response_data.actual != "" &&
          jsonData.response_data.actual != null
        ) {
          setselectedStartDate(jsonData.response_data.actual);
          // Updating the choice array with the actual value
          const updatedData = {
            ...jsonData,
            response_data: {
              ...jsonData.response_data,
              choice: [
                ...jsonData.response_data.choice,
                jsonData.response_data.actual,
              ],
            },
          };

          setStartDateDropDown(updatedData);
        } else {
          setStartDateDropDown(jsonData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error (e.g., show an error message)
      }
    };
    fetchData();
  }, [generateLoanAgreementStartDate]);
  useEffect(() => {
    clearLocalStorage();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      //dispatch(GetEsignedLoanApplication(selectedLoanId));
      if (getapplicationformDocument.status_code === 200) {
        if (getapplicationformDocument.base64_file) {
          convertBase64toPDF(getapplicationformDocument, "Application Form");
        }
        // dispatch(GetEsanctionLetter(selectedLoanId));
        if (getsanctionLetterDocument.status_code === 200) {
          if (getsanctionLetterDocument.base64_file) {
            convertBase64toPDF(getsanctionLetterDocument, "Sanction Letter");
          }
          // dispatch(GetELoanAgreement(selectedLoanId));
          if (getloanagreementDocument.status_code === 200) {
            if (getloanagreementDocument.base64_file) {
              convertBase64toPDF(getloanagreementDocument, "Loan Agreement");
            }
          }
        }
      }
    };
    fetchData();
  }, [
    getapplicationformDocument,
    getsanctionLetterDocument,
    getloanagreementDocument,
  ]);

  const clearLocalStorage = () => {
    dispatch(ESignCheckClearData());
    dispatch(ESignClearData());
    dispatch(GenerateLoanAgreementClearData());
  };

  const [pdfUrlView, setPdfUrlView] = React.useState();
  const [applicationFormpdfUrlView, setapplicationFormpdfUrlView] =
    React.useState();
  const [sanctionLetterpdfUrlView, setsanctionLetterpdfUrlView] =
    React.useState();
  const [loanAgreementpdfUrlView, setloanAgreementpdfUrlView] =
    React.useState();

  useEffect(() => {
    previewPDF();
  }, [generateLoanApplicationResponse]);
  useEffect(() => {
    dispatch(
      GenerateLoanApplicationDigitalLoans(selectedLoanId, handleLogoutButton)
    );
  }, []);
  const previewPDF = () => {
    if (generateLoanApplicationResponse) {
      if (generateLoanApplicationResponse.length != 0) {
        const base64Content = generateLoanApplicationResponse.file_base64_str;
        const base64Data = base64Content;
        const pdfData = atob(base64Data);
        const uint8Array = new Uint8Array(pdfData.length);
        for (let i = 0; i < pdfData.length; i++) {
          uint8Array[i] = pdfData.charCodeAt(i);
        }
        const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrlView(pdfUrl);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      }
    }
  };
  const convertBase64toPDF = (document, type) => {
    const base64Content = document.file_base64_str;
    const base64Data = base64Content;
    const pdfData = atob(base64Data);
    const uint8Array = new Uint8Array(pdfData.length);
    for (let i = 0; i < pdfData.length; i++) {
      uint8Array[i] = pdfData.charCodeAt(i);
    }
    const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    if (type == "Application Form") {
      setapplicationFormpdfUrlView(pdfUrl);
    }
    if (type == "Sanction Letter") {
      setsanctionLetterpdfUrlView(pdfUrl);
    }
    if (type == "Loan Agreement") {
      setloanAgreementpdfUrlView(pdfUrl);
    }

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  };
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPage) => prevPage - 1);
    }
  };

  //--------------------------------------------------------------------------------------
  const handleDownload = () => {
    fetch(pdfUrlView)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Loan_Application_Form.pdf"; // Change the filename if needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };

  const handleDownloadSanction = () => {
    fetch(pdfUrlViewSacntionLetter)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Sanction_Letter.pdf"; // Change the filename if needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };
  const handleDownloadLoanAgreement = () => {
    fetch(pdfUrlViewLoanAgreement)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Loan_Agreement_Form.pdf"; // Change the filename if needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };
  //---------------------------------------------------------------------------------------

  //Esign API Response

  const eSignLoading = useSelector(
    (state) => state.BasicDetailsReducers.eSignLoading
  );
  const eSignResponse = useSelector(
    (state) => state.BasicDetailsReducers.eSignResponse
  );

  const eSignSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eSignSuccess
  );

  const eSignFail = useSelector(
    (state) => state.BasicDetailsReducers.eSignFail
  );
  //------------ E sign Loan Agreement ------------
  const eSignAgreementLoading = useSelector(
    (state) => state.BasicDetailsReducers.eSignAgreementLoading
  );
  const eSignAgreementResponse = useSelector(
    (state) => state.BasicDetailsReducers.eSignAgreementResponse
  );

  const eSignAgreementSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eSignAgreementSuccess
  );

  const eSignAgreementFail = useSelector(
    (state) => state.BasicDetailsReducers.eSignAgreementFail
  );
  const eSignAgreementInitiateLoading = useSelector(
    (state) => state.BasicDetailsReducers.eSignAgreementLoading
  );
  const eSignAgreementInitiateSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eSignAgreementSuccess
  );

  const eSignAgreementInitiateFail = useSelector(
    (state) => state.BasicDetailsReducers.eSignAgreementFail
  );
  //Esign Check

  const eSignCheckLoading = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckLoading
  );

  const eSignCheckSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckSuccess
  );

  const eSignCheckResponse = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckResponse
  );

  const eSignCheckFail = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckFail
  );

  //E Mandate
  //Auth -> it will be either a mobile number or email ->
  //Where user will get a link to initate the eMandate process

  const [auth, setAuth] = React.useState(localStorage.getItem("EmailId"));
  const [applicationFlag, setapplicationFlag] = React.useState(false);
  const [sanctionFlag, setsanctionFlag] = React.useState(false);
  const [agreementFlag, setagreementFlag] = React.useState(false);

  const handleAuthChange = (e) => {
    setAuth(e);
  };

  const handleEmandate = () => {
    dispatch(Enach(selectedLoanId, auth, handleLogoutButton));
  };

  //E-Mandate/Enach - Initate API Response

  const eNachLoading = useSelector(
    (state) => state.BasicDetailsReducers.eNachLoading
  );

  const eNachResponse = useSelector(
    (state) => state.BasicDetailsReducers.eNachResponse
  );

  const eNachSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eNachSuccess
  );

  const eNachFail = useSelector(
    (state) => state.BasicDetailsReducers.eNachFail
  );

  // Enach Status
  const eNachStatusLoading = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusLoading
  );
  const eNachStatusSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusSuccess
  );
  const eNachStatusResponse = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusResponse
  );
  const eNachStatusFail = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusFail
  );

  // Get Sanction Letter

  const handleSanctionLetter = () => {
    dispatch(GetSanctionLetter(selectedLoanId, handleLogoutButton));
  };

  //Function to initiate the Esign For Sanaction Letter

  //FUnction make pdf view for sanction letter

  const [pdfUrlViewSacntionLetter, setPdfUrlViewSacntionLetter] =
    React.useState();
  const [pdfUrlViewLoanAgreement, setpdfUrlViewLoanAgreement] =
    React.useState();
  useEffect(() => {
    previewPDFSactionLetter();
  }, [getSanctionLetterResponse]);
  useEffect(() => {
    previewPDFLoanAgreement();
  }, [generateLoanAgreementResponse]);
  const previewPDFSactionLetter = () => {
    if (getSanctionLetterResponse) {
      if (getSanctionLetterResponse.data) {
        const base64Content = getSanctionLetterResponse.data;
        const base64Data = base64Content;
        const pdfData = atob(base64Data);
        const uint8Array = new Uint8Array(pdfData.length);
        for (let i = 0; i < pdfData.length; i++) {
          uint8Array[i] = pdfData.charCodeAt(i);
        }
        const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrlViewSacntionLetter(pdfUrl);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      }
    }
  };
  const previewPDFLoanAgreement = () => {
    if (generateLoanAgreementResponse) {
      if (generateLoanAgreementResponse?.file_base64_str) {
        const base64Content = generateLoanAgreementResponse.file_base64_str;
        const base64Data = base64Content;
        const pdfData = atob(base64Data);
        const uint8Array = new Uint8Array(pdfData.length);
        for (let i = 0; i < pdfData.length; i++) {
          uint8Array[i] = pdfData.charCodeAt(i);
        }
        const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setpdfUrlViewLoanAgreement(pdfUrl);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      }
    }
  };

  //---------------------------------------------------------------

  const handleEsignSanctionLetter = () => {
    dispatch(EnachClearData());
    dispatch(
      ESign(
        selectedLoanId,
        getSanctionLetterResponse.data,
        "eSign_sanction_letter",
        handleLogoutButton
      )
    );
  };

  useEffect(() => {
    saveSactionLetter();
  }, [eSignSuccess]);
  // useEffect(() => {
  //   saveLoanAgreementLetter();
  // }, [eSignAgreementSuccess]);
  const saveSactionLetter = () => {
    if (Object.keys(getSanctionLetterResponse).length) {
      if (getSanctionLetterResponse.data != "") {
        dispatch(
          SaveSanctionLetter(
            selectedLoanId,
            getSanctionLetterResponse.data,
            handleLogoutButton
          )
        );
      }
    }
  };
  const saveLoanAgreementLetter = () => {
    if (Object.keys(generateLoanAgreementResponse).length) {
      if (generateLoanAgreementResponse.data != "") {
        dispatch(
          SaveLoanAgreement(
            selectedLoanId,
            generateLoanAgreementResponse.data,
            handleLogoutButton
          )
        );
      }
    }
  };
  //--------------------- Status of e -For all id -----------------------

  const statusLoading = useSelector(
    (state) => state.BasicDetailsReducers.statusLoading
  );
  const statusSuccess = useSelector(
    (state) => state.BasicDetailsReducers.statusSuccess
  );
  const statusResponse = useSelector(
    (state) => state.BasicDetailsReducers.statusResponse
  );
  const statusFail = useSelector(
    (state) => state.BasicDetailsReducers.statusFail
  );
  const downloadesigneddocuments = (type) => {
    if (type == "Application Form") {
      window.open(getapplicationformDocument.blob_url, "_self");
    }
    if (type == "Sanction Letter") {
      window.open(getsanctionLetterDocument.blob_url, "_self");
    }
    if (type == "Loan Agreement") {
      window.open(getloanagreementDocument.blob_url, "_self");
    }
  };
  const handleStepClick = async (index) => {
    setActiveStep(index);
    console.log("Active Step is ", index);
    if (index == 0) {
      if (!generateLoanApplicationResponse?.is_signed) {
        dispatch(
          GenerateLoanApplicationDigitalLoans(
            selectedLoanId,
            handleLogoutButton
          )
        );
      }
    }
    if (index == 1) {
      dispatch(
        GenerateLoanApplicationDigitalLoans(selectedLoanId, handleLogoutButton)
      );
      if (!generateLoanAgreementResponse.is_signed) {
        const startDate = await dispatch(
          GetELoanAgreementStartDate(selectedLoanId, handleLogoutButton)
        );
      }
    }
    if (index == 2) {
      dispatch(
        GenerateLoanAgreementDigitalLoan(selectedLoanId, handleLogoutButton)
      );
      dispatch(EnachStatus(selectedLoanId, handleLogoutButton));
    }
  };

  return (
    <>
      <div>
        {/* Your component content */}
        <SessionModal isOpen={showAlert} onClose={handleCloseErrorModal1} />
      </div>
      <Dialog open={showLoanDisbursedPopup} onClose={handleLoanDisbursedPopupClose}>
        <div style={{ textAlign: 'center', padding: '20px', marginTop: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#d4edda', borderRadius: '4px', marginBottom: '10px' }}>
          <CheckCircleIcon style={{ color: '#155724', marginRight: '8px' }} />
          <Typography variant="h6">Transaction Success</Typography>
          </div>
          <div style={{ textAlign: 'left' }}>
              <Typography variant="h4" style={{ marginBottom: '10px' }}>Disbursement Details</Typography>
              <Typography style={{ fontSize: '19px' }}> <span style={{ fontWeight: 'bold' }}>Beneficiary Account Number:</span> xxxxxxxx4436</Typography>
              <Typography style={{ fontSize: '19px' }}> <span style={{ fontWeight: 'bold' }}>Beneficiary Name:</span> Shashank Gandhi</Typography>
              <Typography style={{ fontSize: '19px' }}> <span style={{ fontWeight: 'bold' }}>Disbursed Amount:</span> 1,20,000 </Typography>
              <Typography style={{ fontSize: '19px' }}> <span style={{ fontWeight: 'bold' }}>UTR:</span> FBBT240920811327</Typography>
          </div>
        </div>
      </Dialog>
      {statusLoading ? (
        <>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                m: 5,
                mt: 4,
                ml: 0,
              }}
            >
              <CircularProgressWithLabel />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", ml: 5, ml: 0 }}
            >
              <Alert severity="info">
                Please hold for few seconds , we are fetching your details.
              </Alert>
            </Grid>
          </Grid>
        </>
      ) : null}
      {statusResponse.state === "success" && !statusLoading ? (
        <>
          <InitateDebit loanID={selectedLoanId} />
        </>
      ) : null}
      {!statusLoading && statusResponse.state == "failed" ? (
        <>
          <Grid container>
            <Grid item xs={12} md={6} sx={{ p: 5 }}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label} onClick={() => handleStepClick(index)}>
                    <StepLabel
                      optional={
                        index === 4 ? (
                          <Typography variant="caption">Last step</Typography>
                        ) : null
                      }
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      {activeStep === 0 ? (
                        <>
                          <Grid container>
                            <Typography>{step.description}</Typography>
                            <br />
                            {generateLoanApplicationLoading ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 1,
                                    ml: 0,
                                  }}
                                >
                                  <CircularProgressWithLabel />
                                </Grid>
                              </>
                            ) : null}

                            {Object.keys(generateLoanApplicationResponse)
                              .length ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 5, ml: 0 }}
                                >
                                  <Alert severity="success">
                                    Application form is successfully generated.
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}

                            {generateLoanApplicationFail ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 5, ml: 0 }}
                                >
                                  <Alert severity="error">
                                    Sorry! Failed to generate application form
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                            {Object.keys(generateLoanApplicationResponse)
                              .length ? (
                              <>
                                <Grid container>
                                  {generateLoanApplicationResponse ? (
                                    <>
                                      <Grid item xs={12} sx={{ pt: 4 }}>
                                        <Divider />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          m: 5,
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          mt: 4,
                                          mb: 3,
                                          ml: 0,
                                        }}
                                      >
                                        <Button
                                          variant="outlined"
                                          onClick={handleEsign}
                                          disabled={
                                            generateLoanApplicationResponse.is_signed
                                          }
                                          endIcon={<DocumentScannerIcon />}
                                          sx={{ textTransform: "capitalize" }}
                                        >
                                          Initiate eSign
                                        </Button>
                                      </Grid>
                                    </>
                                  ) : null}
                                </Grid>
                              </>
                            ) : null}
                            {eSignLoading ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 1,
                                  }}
                                >
                                  <CircularProgressWithLabel />
                                </Grid>
                              </>
                            ) : null}
                            {eSignFail ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 0 }}
                                >
                                  <Alert severity="error">
                                    Sorry! Failed to complete eSign
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                            {eSignSuccess ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 2,
                                    ml: 0,
                                  }}
                                >
                                  <Alert severity="info">
                                    To finalize the eSign procedure for your
                                    account, we have sent an email to your
                                    registered email address with all the
                                    necessary instructions and a link to
                                    complete the process. Kindly check your
                                    inbox to find the email from our team.
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </>
                      ) : null}

                      {activeStep === 1 ? (
                        <>
                          {/* <Grid container>
                            <Grid item xs={12} sx={{ mt: 3 }}>
                              <Alert severity="info">
                                Please skip the step for now and go next
                              </Alert>
                            </Grid>
                          </Grid> */}
                          <Grid container>
                            <Typography>{step.description}</Typography>
                            <br />
                            <Grid
                              item
                              xs={12}
                              md={4}
                              sx={{
                                m: 2,
                                ml: 0,
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <TextField
                                select
                                onChange={(e) => handleSelectChange(e)}
                                fullWidth
                                label="Select EMI start date"
                                disabled={
                                  StartDateDropDown?.response_data.actual !=
                                    "" &&
                                  StartDateDropDown?.response_data.actual !=
                                    null
                                }
                                value={selectedStartDate}
                                sx={{
                                  "&:hover": {
                                    borderLeft: "5px solid #0093fb",
                                    borderRadius: 1,
                                  },
                                }}
                              >
                                {StartDateDropDown?.response_data.choice.map(
                                  (option, key) => (
                                    <MenuItem key={key} value={option}>
                                      {option}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                m: 2,
                                ml: 0,
                                display: "flex",
                                justifyContent: "flex-start",
                                mt: 2,
                              }}
                            >
                              <Button
                                onClick={handleLoanAgreement}
                                variant="outlined"
                                endIcon={<AssignmentIcon />}
                                disabled={
                                  getloanagreementDocument.status_code ===
                                    200 &&
                                  selectedStartDate != "" &&
                                  selectedStartDate != null
                                }
                                sx={{ textTransform: "capitalize" }}
                              >
                                Generate Loan Agreement
                              </Button>
                            </Grid>
                            {generateLoanAgreementLoading ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 1,
                                    ml: 0,
                                  }}
                                >
                                  <CircularProgressWithLabel />
                                </Grid>
                              </>
                            ) : null}

                            {Object.keys(generateLoanAgreementResponse)
                              .length ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 5, ml: 0 }}
                                >
                                  <Alert severity="success">
                                    Loan Agreement is successfully generated.
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}

                            {generateLoanAgreementFail ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 5, ml: 0 }}
                                >
                                  <Alert severity="error">
                                    Sorry! Failed to generate application form
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                            <Grid container>
                              {generateLoanAgreementResponse?.file_base64_str ? (
                                <>
                                  <Grid item xs={12} sx={{ pt: 4 }}>
                                    <Divider />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      m: 5,
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      mt: 4,
                                      mb: 3,
                                      ml: 0,
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      onClick={handleEsignLoanAgreement}
                                      disabled={
                                        generateLoanAgreementResponse.is_signed ||
                                        !generateLoanApplicationResponse.is_signed
                                      }
                                      endIcon={<DocumentScannerIcon />}
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      Initiate eSign
                                    </Button>
                                  </Grid>
                                </>
                              ) : null}
                            </Grid>

                            {eSignAgreementInitiateFail ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 0 }}
                                >
                                  <Alert severity="error">
                                    Sorry! Failed to complete eSign
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                            {eSignAgreementInitiateSuccess ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 2,
                                    ml: 0,
                                  }}
                                >
                                  <Alert severity="info">
                                    To finalize the eSign procedure for your
                                    account, we have sent an email to your
                                    registered email address with all the
                                    necessary instructions and a link to
                                    complete the process. Kindly check your
                                    inbox to find the email from our team.
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </>
                      ) : null}
                      {activeStep === 2 ? (
                        <>
                          <Typography>{step.description}</Typography>
                          <br />
                          {getSanctionLetterFlagLoading ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 1,
                                }}
                              >
                                <CircularProgressWithLabel />
                              </Grid>
                            </>
                          ) : null}
                          {getSanctionLetterFlagFail ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{ display: "flex", ml: 0 }}
                              >
                                <Alert severity="error">
                                  Sorry! Sanction letter is not singed
                                </Alert>
                              </Grid>
                            </>
                          ) : null}

                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              m: 5,
                              mt: 3,
                              mb: 2,
                              ml: 0,
                            }}
                          >
                            <Typography sx={{ fontWeight: 900 }}>
                              Kindly share your mobile number or email, in order
                              to proceed with your request!
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            sx={{ display: "flex", mt: 2 }}
                          >
                            <Grid item xs={6} sx={{}}>
                              <TextField
                                type="text"
                                size="small"
                                name="auth"
                                fullWidth
                                disabled={true}
                                id="auth"
                                label="Mobile Number / Email Address"
                                value={auth}
                                onChange={(e) =>
                                  handleAuthChange(e.target.value)
                                }
                              ></TextField>
                            </Grid>
                            {statusResponse.initiate_mandate ? (
                              <Grid item xs={6} sx={{}}>
                                <Button
                                  variant="outlined"
                                  onClick={handleEmandate}
                                  disabled={
                                    eNachSuccess 
                                  }
                                  endIcon={<AccountBalanceIcon />}
                                  sx={{ textTransform: "capitalize", p: 0.87 }}
                                >
                                  Initiate Request
                                </Button>
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 3,
                                  mb: 2,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="info">
                                  {statusResponse.error}
                                </Alert>
                              </Grid>
                            )}
                          </Grid>
                          {eNachLoading ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  m: 5,
                                  mt: 3,
                                  ml: 0,
                                }}
                              >
                                <CircularProgressWithLabel />
                              </Grid>
                            </>
                          ) : null}
                          {eNachFail ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 2,
                                  mb: 3,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="error">
                                  {eNachResponse?.message}
                                </Alert>
                              </Grid>
                            </>
                          ) : null}
                          {eNachSuccess ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 2,
                                  mb: 3,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="info">
                                  {eNachResponse
                                    ? eNachResponse.message
                                    : "Sorry! Failed to process the request!"}
                                </Alert>
                              </Grid>
                            </>
                          ) : null}
                        </>
                      ) : null}
                      {activeStep === 3 ? (
                        <>
                          {eNachStatusLoading ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 1,
                                  ml: 0,
                                }}
                              >
                                <CircularProgressWithLabel />
                              </Grid>
                            </>
                          ) : null}
                          {eNachStatusFail ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 2,
                                  mb: 3,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="error">
                                  Sorry! Failed to confirm the eMandate details!
                                </Alert>
                              </Grid>
                            </>
                          ) : null}
                          {eNachStatusSuccess ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 2,
                                  mb: 3,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="info">
                                  {eNachStatusResponse
                                    ? eNachStatusResponse.error
                                    : ""}
                                </Alert>
                              </Grid>
                            </>
                          ) : null}
                        </>
                      ) : null}

                      <Box sx={{ mb: 2 }}>
                        <Grid container>
                          {index !== 0 && index !== 1 && index !== 2 ? (
                            <>
                            <Alert severity="info"> Loan will be disbursed in the following account: xxxxxxxx4436 </Alert>
                              <Grid item xs={12} sx={{ m: 5, ml: 1, mr: 1 }}>
                                <Button
                                  variant="contained"
                                  onClick={disburseLoan}
                                  // disabled={
                                  //   Object.keys(generateLoanApplicationResponse)
                                  //     .length === 0 ||
                                  //   Object.keys(generateLoanAgreementResponse)
                                  //     .length === 0 ||
                                  //   !eNachStatusResponse.state ||
                                  //   eNachStatusResponse.state === "failed"
                                  // }
                                  sx={{
                                    mt: 1,
                                    mr: 1,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {index === steps.length - 1
                                    ? "Finish"
                                    : "Continue"}
                                </Button>
                                {/* <Button
                                  disabled={index === 0}
                                  onClick={handleBack}
                                  sx={{
                                    mt: 1,
                                    mr: 1,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Back
                                </Button> */}
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            {Object.keys(generateLoanApplicationResponse).length &&
            activeStep === 0 ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ border: "1px solid #000000", mt: 5 }}
                >
                  <div
                    style={{
                      padding: "10px",
                      height: "550px",
                      overflow: "auto",
                    }}
                  >
                    <div style={{ height: "500px" }}>
                      <Document
                        file={pdfUrlView}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{ workerSrc: "/pdf.worker.js" }}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                    {numPages && (
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    )}
                  </div>
                  {numPages && (
                    <Grid container>
                      <Grid item xs={12} md={9}>
                        <div>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handlePrevPage}
                            disabled={pageNumber === 1}
                          >
                            Previous Page
                          </Button>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handleNextPage}
                            disabled={pageNumber === numPages}
                          >
                            Next Page
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          endIcon={<DownloadIcon />}
                          onClick={handleDownload}
                          sx={{ textTransform: "capitalize" }}
                        >
                          Download Application
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : null}
            {Object.keys(generateLoanAgreementResponse).length &&
            activeStep === 1 ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ border: "1px solid #000000", mt: 5 }}
                >
                  <div
                    style={{
                      padding: "10px",
                      height: "550px",
                      overflow: "auto",
                    }}
                  >
                    <div style={{ height: "500px" }}>
                      <Document
                        file={pdfUrlViewLoanAgreement}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{ workerSrc: "/pdf.worker.js" }}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                    {numPages && (
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    )}
                  </div>
                  {numPages && (
                    <Grid container>
                      <Grid item xs={12} md={9}>
                        <div>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handlePrevPage}
                            disabled={pageNumber === 1}
                          >
                            Previous Page
                          </Button>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handleNextPage}
                            disabled={pageNumber === numPages}
                          >
                            Next Page
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          endIcon={<DownloadIcon />}
                          onClick={handleDownloadLoanAgreement}
                          sx={{ textTransform: "capitalize" }}
                        >
                          Download Agreement
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : null}
            {activeStep === 0 &&
            getapplicationformDocument.status_code === 200 ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ border: "1px solid #000000", mt: 5 }}
                >
                  <div
                    style={{
                      padding: "10px",
                      height: "550px",
                      overflow: "auto",
                    }}
                  >
                    <div style={{ height: "500px" }}>
                      <Document
                        file={applicationFormpdfUrlView}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{ workerSrc: "/pdf.worker.js" }}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                    {numPages && (
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    )}
                  </div>
                  {numPages && (
                    <Grid container>
                      <Grid item xs={12} md={9}>
                        <div>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handlePrevPage}
                            disabled={pageNumber === 1}
                          >
                            Previous Page
                          </Button>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handleNextPage}
                            disabled={pageNumber === numPages}
                          >
                            Next Page
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          endIcon={<DownloadIcon />}
                          onClick={() =>
                            downloadesigneddocuments("Application Form")
                          }
                          sx={{ textTransform: "capitalize" }}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : null}
            {activeStep === 1 &&
            getloanagreementDocument.status_code === 200 ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ border: "1px solid #000000", mt: 5 }}
                >
                  <div
                    style={{
                      padding: "10px",
                      height: "550px",
                      overflow: "auto",
                    }}
                  >
                    <div style={{ height: "500px" }}>
                      <Document
                        file={loanAgreementpdfUrlView}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{ workerSrc: "/pdf.worker.js" }}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                    {numPages && (
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    )}
                  </div>
                  {numPages && (
                    <Grid container>
                      <Grid item xs={12} md={9}>
                        <div>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handlePrevPage}
                            disabled={pageNumber === 1}
                          >
                            Previous Page
                          </Button>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={handleNextPage}
                            disabled={pageNumber === numPages}
                          >
                            Next Page
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          endIcon={<DownloadIcon />}
                          onClick={() =>
                            downloadesigneddocuments("Loan Agreement")
                          }
                          sx={{ textTransform: "capitalize" }}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : null}
          </Grid>
        </>
      ) : null}
    </>
  );
}
