import React from "react";
import pic2 from "./../../Assets/CoverDMS.png";
import "./../../Components/styles.css";
import Grid from "@mui/material/Grid";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useState } from "react";
export default function CoverPage() {
  return (
    <div>
      <Grid item xs={12}>
        <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}></Grid>
        <a href="/Login">
          <img className="cover_img" src={pic2} />
        </a>
      </Grid>
    </div>
  );
}
