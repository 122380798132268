import React from "react";
import "./../../Components/styles.css";
import pic1 from "./../../Assets/customLogo.png";
import pic2 from "./../../Assets/login.svg";
import Grid from "@mui/material/Grid";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useState } from "react";
import { base_url2 } from "./../../constants";
import Axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Button, TextField, Typography, Divider } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lightTheme } from "./../../Common/Theme";
import { useNavigate } from "react-router-dom";
import {
  saveRegistrationDetails,
  resetPasswordChange,
  getBranches,
  UserQueryClearData,
} from "../../Components/BasicDetails/BasicDetailsActions/BasicDetailsActions";
import MenuItem from "@mui/material/MenuItem";
import { OTPInputField } from "react-otp-input-type";
import CustomLoader from "./../../Common/corestratLoader";
import LinearProgress from "@mui/material/LinearProgress";
import Footer from "./../../Common/Footer";
import Alert from "@mui/material/Alert";
import GenInsight from "../GenInsight/GenInsight";
import Header from "../../Common/Header/Header";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default function Login() {
  const [error, setError] = React.useState(false);
  const [islogin, setislogin] = React.useState(true);
  const [loginCompleted, setloginCompleted] = React.useState(true);
  const [seconds, setSeconds] = useState(120);
  const [emailError, setEmailError] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [duplicateRecord, setduplicateRecord] = React.useState(false);
  const [UsernameValid, setUsernameValid] = React.useState(false);
  const [OTPerror, setOTPerror] = React.useState(false);
  const [isValidPassword, setisValidPassword] = React.useState(false);
  const [message, setmessage] = React.useState("");
  const [resetPasswordFlag, setresetPasswordFlag] = React.useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneNoError, setPhoneNoError] = useState(false);
  const GenericMobileNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10,13}$/;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  // useEffect(() => {
  //   let interval = null;
  //   if (seconds > 0) {
  //     interval = setInterval(() => {
  //       setSeconds((seconds) => seconds - 1);
  //     }, 1000);
  //   }
  //   return () => clearInterval(interval);
  // }, [seconds]);
  const OtpTimer = () => {
    let interval = null;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  };
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [resetPasswordFields, setresetPasswordFields] = useState({
    username: "",
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [registration, setregistration] = useState({
    username: "",
    email: "",
    mobileNo: "",
    password: "",
    repassword: "",
  });
  const [selectedBranchName, setselectedBranchName] = React.useState("");
  const branchName = useSelector(
    (state) => state.BasicDetailsReducers.branchname
  );
  useEffect(() => {
    dispatch(getBranches());
  }, []);
  useEffect(() => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
  }, []);
  const setLogin = (login, e) => {
    setCredentials({ ...credentials, username: "", password: "" });
    setregistration({
      ...registration,
      username: "",
      email: "",
      mobileNo: "",
      password: "",
      repassword: "",
    });
    setislogin(login);
    setduplicateRecord(false);
    setError(false);
  };

  const handleInputChangePassword = (e) => {
    setresetPasswordFields({
      ...resetPasswordFields,
      [e.target.name]: e.target.value,
    });
    if (e.target.name == "confirmPassword") {
      if (e.target.value == resetPasswordFields.password) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  };
  const changePassword = async () => {
    if (emailError || isValid) {
    } else {
      const results = await resetPasswordChange(resetPasswordFields);
      setError(false);
      if (results.data.msg != "Password Updated") {
        setmessage(results.data.msg);
      } else {
        resetPassword();
      }
    }
  };
  const resetPassword = () => {
    setresetPasswordFlag(!resetPasswordFlag);
    setCredentials({ ...credentials, username: "", password: "" });
    setresetPasswordFields({
      ...resetPasswordFields,
      username: "",
      oldPassword: "",
      password: "",
      confirmPassword: "",
    });
    setmessage("");
    setisValidPassword(false);
  };
  const handleClose = () => {
    setOpen1(false);
    setduplicateRecord(false);
    setloginCompleted(true);
    setCredentials({ ...credentials, username: "", password: "" });
    setregistration({
      ...registration,
      username: "",
      email: "",
      mobileNo: "",
      password: "",
      repassword: "",
    });
  };
  const handleInputChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleInputChangeRegistration = (e) => {
    if (e.target.name == "email") {
      const valueWithoutSpaces = e.target.value.replace(/\s/g, "");
      setregistration({ ...registration, [e.target.name]: valueWithoutSpaces });
    } else {
      setregistration({ ...registration, [e.target.name]: e.target.value });
    }
    if (e.target.name == "email") {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (regex.test(e.target.value)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
    if (e.target.name == "mobileNo") {
      setregistration({ ...registration, [e.target.name]: e.target.value });
      // Check if the input matches the generic regex pattern
      const isValid = GenericMobileNumberRegex.test(e.target.value);

      // Update the error state based on the validation result
      setPhoneNoError(!isValid);
    }
    if (e.target.name == "repassword") {
      if (e.target.value == registration.password) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  };
  const [open1, setOpen1] = React.useState(false);
  const handleLoginSignup = async (e) => {
    setduplicateRecord(false);
    setloginCompleted(false);
    if (registration.email == "") {
      setEmailError(true);
    }
    if (
      registration.email == "" ||
      registration.username == "" ||
      registration.password == "" ||
      registration.repassword == "" ||
      selectedBranchName == ""
    ) {
      setloginCompleted(true);
      return;
    }
    const matchingBranches = branchName.response_data.filter((branch) =>
      branch.Branch_Name.includes(selectedBranchName)
    );

    // Now matchingBranches contains objects where the bank name is found in branchName
    if (emailError || isValid || phoneNoError) {
    } else {
      setOTPerror(false);
      const response = await saveRegistrationDetails(
        registration,
        matchingBranches
      );

      if (
        response.data.message &&
        response.data.message?.trim() === "User Exists Already!"
      ) {
        setduplicateRecord(true);
        setloginCompleted(true);
      } else {
        OtpTimer();
        setOpen1(true);
        setloginCompleted(true);
        localStorage.setItem("EmailId", registration.email);
      }
      //navigate("/BasicDetails");
    }
  };
  const resendOTP = async (e) => {
    const matchingBranches = branchName.response_data.filter((branch) =>
      branch.Branch_Name.includes(selectedBranchName)
    );
    const response = await saveRegistrationDetails(
      registration,
      matchingBranches
    );
  };
  const handleOTPChange = (val) => {
    setOtp(val);
  };
  const verifyOTP = () => {
    setOTPerror(false);
    setloginCompleted(false);
    Axios.post(`${base_url2}/dla_user_otp_verify`, {
      email_id: registration.email,
      email_verification_code: otp,
    }).then((result) => {
      if (result.data.data[0].Result != "success") {
        setOTPerror(true);
        setloginCompleted(true);
      } else {
        setOTPerror(false);
        setislogin(true);
        setloginCompleted(true);
        //navigate("/BasicDetails");
      }
    });
  };
  const validateUsers = () => {
    setloginCompleted(false);
    setError(false);
    
    Axios.post(`${base_url2}/user_login`, {
      email_id: credentials.username,
      login_password: credentials.password,
    }).then((result) => {
      if (!result.data.response_message?.startsWith("SUCCESS")) {
        setError(true);
        setloginCompleted(true);
      } else {
        setError(false);
        localStorage.setItem("EmailId", result.data.response_data.email_id);
        localStorage.setItem("branch", result.data.response_data.branch);
        localStorage.setItem("jwt_token", result.data.response_data.jwt_token);
        setloginCompleted(true);
        navigate("/BasicDetails");
        dispatch(UserQueryClearData());
        localStorage.removeItem("LoanApplicationId");
      }
    });
  };

  return (
    <ThemeProvider theme={lightTheme}>
      {!loginCompleted ? (
        <Grid item xs={12} sx={{ m: 1 }}>
          <LinearProgress
            sx={{
              color: "#0093fb",
            }}
            size={40}
            thickness={4}
          />
        </Grid>
      ) : null}
      <div
        style={{
          background: "#ffffff",
        }}
      >
        <Header />
      </div>

      <GenInsight />
      <Box
        maxWidth
        align="center"
        style={{
          background: "#ffffff",
          height: "91vh",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          textAlign="center"
          style={{
            width: "61rem",
            height: "91%",
            boxShadow: "0 4px 12px rgb(0 0 0 / 15%)",
            background: "#ffffff",
            borderRadius: "20px",
          }}
        >
          <Grid item xs={12}>
            {!islogin ? (
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={6}>
                  <div
                    style={{
                      width: "100%",
                      height: "99%",
                      backgroundColor: "#0093fb",
                      textAlign: "center",
                      color: "#ffffff",
                      padding: "20px",
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      borderRadius: "20px 0px 0px 20px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "35px",
                        marginBottom: "15%",
                        textAlign: "left",
                        fontWeight: "600",
                      }}
                    >
                      Humanizing the <br />
                      Experience of Finance
                    </div>
                    <div style={{ fontSize: "20px", textAlign: "left" }}>
                      Welcome to Corestrat Finance - your hassle-free banking
                      partner!
                    </div>
                    <br />
                    <br />
                    <div style={{ fontSize: "20px", textAlign: "left" }}>
                      Paperless loans at your fingertips!
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ marginTop: "3%" }}>
                  <Box>
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: "800",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          color: "#000000",
                        }}
                      >
                        Corestrat Instant Loans
                      </span>{" "}
                    </Typography>
                  </Box>
                  <Box style={{ margin: "3%" }}>
                    <Typography variant="h6"> Sign Up </Typography>
                  </Box>
                  <Box style={{ margin: "5%" }}>
                    <Divider />
                  </Box>
                  {duplicateRecord ? (
                    <Alert severity="error">This user already exists!</Alert>
                  ) : (
                    ""
                  )}
                  <div style={{ height: "42vh", overflow: "auto" }}>
                    <Box style={{ margin: "3%" }}>
                      <TextField
                        fullWidth
                        error={UsernameValid}
                        label="Name (as per PAN) *"
                        variant="standard"
                        name="username"
                        value={registration.username}
                        onChange={(e) => handleInputChangeRegistration(e)}
                        helperText={
                          UsernameValid ? "Username cannot be Empty" : null
                        }
                      />
                    </Box>
                    <Box style={{ margin: "3%" }}>
                      <TextField
                        fullWidth
                        label="Email *"
                        error={emailError}
                        variant="standard"
                        value={registration.email}
                        name="email"
                        onChange={(e) => handleInputChangeRegistration(e)}
                        helperText={emailError ? "Email is Invalid." : null}
                      />
                    </Box>
                    <Box style={{ margin: "3%" }}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Mobile No. (with Country Code)"
                        variant="standard"
                        name="mobileNo"
                        value={registration.mobileNo}
                        onChange={(e) => handleInputChangeRegistration(e)}
                        error={phoneNoError}
                        helperText={phoneNoError ? "Invalid mobile number" : ""}
                      />
                    </Box>
                    <Box style={{ margin: "3%" }}>
                      <TextField
                        fullWidth
                        label="Password *"
                        type="password"
                        variant="standard"
                        value={registration.password}
                        name="password"
                        onChange={(e) => handleInputChangeRegistration(e)}
                      />
                    </Box>
                    <Box style={{ margin: "3%" }}>
                      <TextField
                        fullWidth
                        label="Repeat Password *"
                        type="password"
                        error={isValid}
                        variant="standard"
                        value={registration.repassword}
                        name="repassword"
                        onChange={(e) => handleInputChangeRegistration(e)}
                        helperText={isValid ? "Password Doesnt Match" : null}
                      />
                    </Box>
                    <Box style={{ margin: "3%" }}>
                      <TextField
                        fullWidth
                        select
                        label="Branch Name"
                        id="Branch Name"
                        value={selectedBranchName}
                        onChange={(event) =>
                          setselectedBranchName(event.target.value)
                        }
                        sx={{
                          textAlign: "left", // Align text to the left
                          // Add other styling properties if needed
                        }}
                        variant="standard"
                      >
                        {/* Static options */}
                        {branchName?.response_data?.map((option) => (
                          <MenuItem
                            key={option.Branch_Name}
                            value={option.Branch_Name}
                            sx={{
                              fontFamily: "'Roboto Condensed', sans-serif",
                              "&:hover": {
                                borderLeft: "5px solid #0093fb",
                                borderRadius: 1,
                              },
                            }}
                          >
                            {option.Branch_Name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </div>
                  <Box textAlign="center" style={{ margin: "5%" }}>
                    <Button
                      size="large"
                      variant="contained"
                      style={{
                        background: "#0093fb",
                        color: "#ffffff",
                        borderRadius: "20px",
                      }}
                      onClick={(e) => handleLoginSignup(e)}
                    >
                      Sign Up
                    </Button>
                    <BootstrapDialog
                      className="optCSS"
                      onClose={handleClose}
                      aria-labelledby="customized-dialog-title"
                      open={open1}
                    >
                      <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                        style={{ fontWeight: "800", color: "#0093fb" }}
                      >
                        Register
                      </BootstrapDialogTitle>

                      <DialogContent>
                        {OTPerror ? (
                          <Box
                            className="errorMessage"
                            style={{ margin: "5%" }}
                          >
                            Invalid OTP
                          </Box>
                        ) : (
                          ""
                        )}
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <Typography
                            sx={{ m: 4, flex: 1 }}
                            component="div"
                            style={{
                              maxWidth: "100% !important",
                              textAlign: "center",
                            }}
                          >
                            We have sent you <b>One Time Password</b> to your
                            email.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <Typography
                            sx={{ m: 4, flex: 1 }}
                            component="div"
                            style={{
                              maxWidth: "100% !important",
                              textAlign: "center",
                              color: "#0093fb",
                            }}
                          >
                            Please Enter OTP
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={6} mt={2}>
                          <Typography
                            sx={{ m: 4, flex: 1 }}
                            component="div"
                            style={{
                              maxWidth: "100% !important",
                              textAlign: "center",
                              fontWeight: "800",
                            }}
                          >
                            {`${minutes}:${
                              remainingSeconds < 10 ? "0" : ""
                            }${remainingSeconds}`}
                          </Typography>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <OTPInputField
                            className="otp"
                            numOfInputs={6}
                            handleChange={handleOTPChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            mt={2}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="buttonText"
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                textTransform: "capitalize",
                                color: "#0093fb",
                                fontWeight: "800",
                                m: 2,
                              }}
                              onClick={(e) => resendOTP(e)}
                              className="buttonText"
                            >
                              Resend OTP
                            </Button>

                            <Button
                              variant="contained"
                              sx={{
                                textTransform: "capitalize",
                                fontWeight: "800",
                                m: 2,
                              }}
                              className="buttonText1"
                              onClick={verifyOTP}
                            >
                              Verify OTP
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            mt={4}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                textTransform: "capitalize",
                                color: "#0093fb",
                                fontWeight: "800",
                              }}
                              onClick={handleClose}
                            >
                              Login with another account
                            </Button>
                          </Grid>
                        </Grid>
                      </DialogContent>
                    </BootstrapDialog>
                  </Box>
                  <Box textAlign="center" style={{ margin: "5%" }}>
                    <Typography variant="body2">
                      {" "}
                      Already have an account ?{" "}
                      <Link onClick={(e) => setLogin(true, e)}>
                        Login here
                      </Link>{" "}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <>
                {!resetPasswordFlag ? (
                  <Grid
                    container
                    spacing={4}
                    style={{
                      height: "87vh",
                    }}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <div
                        style={{
                          width: "100%",
                          height: "99%",
                          backgroundColor: "#0093fb",
                          textAlign: "center",
                          color: "#ffffff",
                          padding: "20px",
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderRadius: "20px 0px 0px 20px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "35px",
                            marginBottom: "15%",
                            textAlign: "left",
                            fontWeight: "600",
                          }}
                        >
                          Humanizing the <br />
                          Experience of Finance
                        </div>
                        <div style={{ fontSize: "20px", textAlign: "left" }}>
                          Welcome to Corestrat Finance - your hassle-free
                          banking partner!
                        </div>
                        <br />
                        <br />
                        <div style={{ fontSize: "20px", textAlign: "left" }}>
                          Paperless loans at your fingertips!
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      style={{ marginTop: "5%" }}
                    >
                      <Box style={{ margin: "3%" }}>
                        <Typography
                          variant="h5"
                          style={{
                            fontWeight: "800",
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              color: "#000000",
                            }}
                          >
                            Corestrat Instant Loans
                          </span>{" "}
                        </Typography>
                      </Box>
                      <Box style={{ margin: "3%" }}>
                        <Typography variant="h6"> Login </Typography>
                      </Box>
                      <Box style={{ margin: "5%" }}>
                        <Divider />
                      </Box>
                      {error ? (
                        <Alert severity="error">
                          Incorrect Email or Password
                        </Alert>
                      ) : (
                        ""
                      )}
                      <Box style={{ margin: "5%" }}>
                        <TextField
                          fullWidth
                          label="Email Id"
                          variant="standard"
                          value={credentials.username}
                          name="username"
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Box>
                      <Box style={{ margin: "5%" }}>
                        <TextField
                          fullWidth
                          label="Password"
                          type="password"
                          variant="standard"
                          name="password"
                          value={credentials.password}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Box>
                      <Box
                        textAlign="right"
                        style={{ margin: "5%" }}
                        sx={{
                          color: "#0093fb !important",
                          fontWeight: "800 !important",
                        }}
                      >
                        <Typography variant="body2">
                          {" "}
                          <Link onClick={(e) => resetPassword()}>
                            Reset Password
                          </Link>{" "}
                        </Typography>
                      </Box>
                      <Box textAlign="center" style={{ margin: "5%" }}>
                        <Button
                          size="large"
                          variant="contained"
                          style={{
                            background: "#0093fb",
                            color: "#ffffff",
                            borderRadius: "20px",
                          }}
                          onClick={validateUsers}
                        >
                          Login
                        </Button>
                      </Box>

                      <Box textAlign="center" style={{ margin: "5%" }}>
                        <Typography variant="body2">
                          {" "}
                          Don't have an account ?{" "}
                          <Link onClick={(e) => setLogin(false, e)}>
                            Register here
                          </Link>{" "}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={6}>
                      <div
                        style={{
                          width: "100%",
                          height: "99%",
                          backgroundColor: "#0093fb",
                          textAlign: "center",
                          color: "#ffffff",
                          padding: "20px",
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderRadius: "20px 0px 0px 20px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "35px",
                            marginBottom: "15%",
                            textAlign: "left",
                            fontWeight: "600",
                          }}
                        >
                          Humanizing the <br />
                          Experience of Finance
                        </div>
                        <div style={{ fontSize: "20px", textAlign: "left" }}>
                          Welcome to Corestrat Finance - your hassle-free
                          banking partner!
                        </div>
                        <br />
                        <br />
                        <div style={{ fontSize: "20px", textAlign: "left" }}>
                          Paperless loans at your fingertips!
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      style={{
                        height: "88vh",
                      }}
                    >
                      <Box style={{ margin: "3%" }}>
                        <Typography
                          variant="h5"
                          style={{
                            fontWeight: "800",
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              color: "#000000",
                            }}
                          >
                            Corestrat Instant Loans
                          </span>{" "}
                        </Typography>
                      </Box>
                      <Box style={{ margin: "3%" }}>
                        <Typography variant="h6"> Login </Typography>
                      </Box>
                      <Box style={{ margin: "5%" }}>
                        <Divider />
                      </Box>
                      <div style={{ height: "45vh", overflow: "auto" }}>
                        {message != "" ? (
                          <Box
                            style={{ marginLeft: "5%", textAlign: "center" }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                ml: 5,
                                ml: 0,
                                justifyContent: "center",
                              }}
                            >
                              <Alert severity="error">{message}</Alert>
                            </Grid>
                          </Box>
                        ) : null}
                        <Box style={{ margin: "5%" }}>
                          <TextField
                            fullWidth
                            label="Email/Mobile No.(with country code)"
                            variant="standard"
                            name="username"
                            value={resetPasswordFields.username}
                            onChange={(e) => handleInputChangePassword(e)}
                            error={!!emailError}
                            helperText={emailError}
                          />
                        </Box>
                        <Box style={{ margin: "5%" }}>
                          <TextField
                            fullWidth
                            label="Old Password"
                            type="password"
                            variant="standard"
                            name="oldPassword"
                            value={resetPasswordFields.oldPassword}
                            onChange={(e) => handleInputChangePassword(e)}
                          />
                        </Box>
                        <Box style={{ margin: "5%" }}>
                          <TextField
                            fullWidth
                            label="New Password"
                            type="password"
                            variant="standard"
                            name="password"
                            value={resetPasswordFields.password}
                            onChange={(e) => handleInputChangePassword(e)}
                          />
                        </Box>
                        <Box style={{ margin: "5%" }}>
                          <TextField
                            fullWidth
                            label="Confirm Password"
                            type="password"
                            variant="standard"
                            name="confirmPassword"
                            value={resetPasswordFields.confirmPassword}
                            onChange={(e) => handleInputChangePassword(e)}
                            error={isValid}
                            helperText={
                              isValid ? "Password Doesnt Match" : null
                            }
                          />
                        </Box>
                      </div>
                      {/* <Box style={{ cursor: "pointer", margin: "5%" }}>
                  <Typography align="left" variant="body2" color="textPrimary">
                    Forgot password
                  </Typography>
                </Box> */}
                      <Box
                        textAlign="right"
                        style={{ marginRight: "5%" }}
                        sx={{
                          color: "#0093fb !important",
                          fontWeight: "800 !important",
                        }}
                      >
                        <Typography variant="body2">
                          {" "}
                          <Link onClick={(e) => resetPassword()}>
                            Login Here
                          </Link>{" "}
                        </Typography>
                      </Box>
                      <Box textAlign="center" style={{ margin: "5%" }}>
                        <Button
                          size="large"
                          variant="contained"
                          style={{
                            background: "#0093fb",
                            color: "#ffffff",
                            borderRadius: "20px",
                          }}
                          onClick={changePassword}
                        >
                          Reset Password
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
