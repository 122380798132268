import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Button, TextField, Typography } from "@mui/material";
import NavBar from "../NavBar/Index";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TableView from "../MyLoan/TableView";
import RepaymentTable from "../MyLoan/Repayment";
import DoneIcon from "@mui/icons-material/Done";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  MyLoan,
  GetLoanDetailsById,
  GetLoanDetails,
  TopbarViewSteps,
} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Footer from "./../../Common/Footer";
import SessionModal from "./../../Common/SessionModel";
import GenInsight from "../GenInsight/GenInsight";
function Myloan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referenceNumber, setReferenceNumber] = React.useState("");
  const [showAlert, setShowAlert] = useState(false);
  const handleSubmit = () => {
    const emailId = localStorage.getItem("EmailId");
    dispatch(MyLoan(referenceNumber, emailId, handleLogoutButton));
  };
  const handleMyLoan = () => {
    dispatch(TopbarViewSteps(5));
    dispatch(GetLoanDetails(handleLogoutButton));
    setReferenceNumber("");
    navigate("/MyLoan");
  };
  const myloanLoading = useSelector(
    (state) => state.BasicDetailsReducers.myloanLoading
  );
  const getLoanDetailsResponse = useSelector(
    (state) => state.BasicDetailsReducers.getLoanDetailsResponse
  );
  //------------------------------------------------------------
  //Getting Loan Id From - URL
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("loanID");

  const loanApplicationId = localStorage.getItem("LoanApplicationId");
  const email = localStorage.getItem("Email");

  useEffect(() => {
    if (code != null) {
      dispatch(GetLoanDetailsById(code, handleLogoutButton));
    } else {
      dispatch(GetLoanDetails(handleLogoutButton));
    }
  }, [code]);

  const loanConfirmLoading = useSelector(
    (state) => state.BasicDetailsReducers.loanConfirmLoading
  );
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };
  const handleCloseErrorModal1 = () => {
    setShowAlert(false);
    navigate("/login");
  };
  return (
    <Grid container sx={{ mb: 8 }}>
      <GenInsight />
      <Grid item xs={12}>
        <NavBar />
      </Grid>
      <div>
        {/* Your component content */}
        <SessionModal isOpen={showAlert} onClose={handleCloseErrorModal1} />
      </div>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 3,
          height: "9vh",
          background: "#e5f6fd",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 900,
            fontSize: "20px",
          }}
        >
          My Loans
        </Typography>
      </Grid>
      <Grid item xs={12} md={5}></Grid>
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "9vh",
          mt: 6,
        }}
      >
        <Typography sx={{ cursor: "pointer", ml: 4 }} onClick={handleMyLoan}>
          My Loans
        </Typography>
        <AssignmentIcon
          onClick={handleMyLoan}
          sx={{ ml: 1, color: "#0093fb" }}
        />
      </Grid>
      <Grid item xs={12} md={4} sx={{ mt: 1 }}>
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={10} sx={{ display: "flex" }}>
            <div
              style={{ height: "30px", borderLeft: "4px solid #0093fb" }}
            ></div>

            <Typography sx={{ ml: 1, mt: 0.3, fontWeight: 600, fontSize: 16 }}>
              Loan Details
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={10} sx={{}}>
            <Typography sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}>
              "Loan details" typically refers to the specific information and
              terms of a loan agreement between a lender and a borrower. This
              may include the amount borrowed, the interest rate, the repayment
              period, any fees or penalties, and other conditions and
              requirements of the loan.
            </Typography>
            {/* <Typography
              sx={{
                mt: 1,
                ml: 1,
                fontWeight: 600,
                fontSize: 12,
                color: "#0093fb",
                cursor: "pointer",
              }}
            >
              Learn More
            </Typography> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={1} sx={{ mt: 1 }}></Grid>
      <Grid item xs={12} md={3} sx={{ mt: 3 }}>
        <TextField
          onChange={(e) => setReferenceNumber(e.target.value)}
          fullWidth
          id="LoanReferenceNumber"
          name="LoanReferenceNumber"
          label="Loan Reference Number"
          value={referenceNumber}
        ></TextField>
      </Grid>
      {/* <Grid item xs={12} md={1} sx={{ mt: 1 }}></Grid> */}
      <Grid item xs={12} md={2} sx={{ mt: 1, pl: 2 }}>
        <Button
          fullWidth
          onClick={handleSubmit}
          variant="contained"
          sx={{ mt: 2.1, p: 1.9, textTransform: "capitalize" }}
        >
          {" "}
          Get Details{" "}
        </Button>
      </Grid>
      <Grid item xs={12} md={2} sx={{ mt: 1 }}></Grid>
      {/* <Grid item xs={1} sx={{ mt: 5 }}></Grid> */}
      {loanConfirmLoading ? (
        <>
          <Grid item xs={10} sx={{ mt: 5 }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sx={{ m: 10 }}>
            <TableView />
          </Grid>
          {/* <Grid item xs={10} sx={{ mt: 5, ml: 15 }}>
            {code != null ? (
              <div>
                {getLoanDetailsResponse.map((Details) => (
                  <div>
                    {Details.loan_status == "APPROVED" ? (
                      <Typography
                        sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}
                      >
                        {" "}
                        Your loan amount of RS{" "}
                        <b>{Details.Sanctioned_amount}</b> has been approved at{" "}
                        <b>{Details.interest_rate}</b> interest rate. Please
                        find the repayment schedule below -{" "}
                      </Typography>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : null}
          </Grid> */}

          {/* <Grid item xs={10} sx={{ mt: 5, ml: 15 }}>
            {getLoanDetailsResponse.map((Details) => (
              <div>
                {Details.loan_status == "APPROVED" ? <RepaymentTable /> : null}
              </div>
            ))}
          </Grid> */}
        </>
      )}

      {/* <Grid item xs={1} sx={{ mt: 5 }}></Grid> */}
      <Footer />
    </Grid>
  );
}

export default Myloan;
