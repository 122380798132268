import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import {
  LoanConfirm,
  BRE,
  GetLoanDetailsById,
} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0093fb",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables() {
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const getLoanDetailsResponse = useSelector(
    (state) => state.BasicDetailsReducers.getLoanDetailsResponse
  );
  useEffect(() => {
    //dispatch(BRE(handleLogoutButton));
    const loanApplicationId = localStorage.getItem("LoanApplicationId");
    const emailId = localStorage.getItem("EmailId");
    if (getLoanDetailsResponse.length == 0) {
      dispatch(GetLoanDetailsById(loanApplicationId, handleLogoutButton));
    }
    //dispatch(LoanConfirm(emailId, handleLogoutButton));
    //setEmail(emailId);
  }, [getLoanDetailsResponse]);
  //Validiuaty data
  const today = new Date();
  today.setDate(today.getDate() + 3);

  const year = today.getFullYear().toString().substr(-2);
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}/${month}/${day}`;
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };
  return (
    <TableContainer component={Paper}>
      {getLoanDetailsResponse && getLoanDetailsResponse != undefined ? (
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: 900 }}>
                Loan ID
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                Reference Number
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                Customer Name
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                Loan Type
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                Amount Requested
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                Status
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                Collateral
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                Validity
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {getLoanDetailsResponse.length >= 0 &&
          getLoanDetailsResponse != undefined ? (
            <TableBody sx={{ p: 2 }}>
              {getLoanDetailsResponse.map((Details) => (
                <StyledTableRow sx={{ p: 2 }}>
                  <StyledTableCell
                    sx={{ fontWeight: 900 }}
                    component="th"
                    scope="row"
                  >
                    {Details?.id}
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                    {Details?.loan_reference_number}
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                    {Details?.customer_name}
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                    {Details?.loan_product_name}
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                    {Details?.loan_amount}
                  </StyledTableCell>
                  {Details?.loan_status != null &&
                  (Details?.loan_status == "DECLINE" ||
                    Details?.loan_status == "Decline") ? (
                    <StyledTableCell
                      sx={{ color: "#FF0000", fontWeight: 900 }}
                      align="center"
                    >
                      {Object.keys(Details).length
                        ? Details?.loan_status.replace(/_/g, " ")
                        : ""}
                    </StyledTableCell>
                  ) : null}
                  {Details?.loan_status != null &&
                  (Details?.loan_status == "Approved" ||
                    Details?.loan_status == "APPROVED") ? (
                    <StyledTableCell
                      sx={{ color: "#339900", fontWeight: 900 }}
                      align="center"
                    >
                      {Object.keys(Details).length
                        ? Details?.loan_status.replace(/_/g, " ")
                        : ""}
                    </StyledTableCell>
                  ) : (
                    <></>
                  )}

                  {Details?.loan_status != null &&
                  Details?.loan_status != "Approved" &&
                  Details?.loan_status != "Decline" &&
                  Details?.loan_status != "APPROVED" &&
                  Details?.loan_status != "DECLINE" ? (
                    <StyledTableCell
                      sx={{ color: "#ff9966", fontWeight: 900 }}
                      align="center"
                    >
                      {Object.keys(Details).length
                        ? Details?.loan_status.replace(/_/g, " ")
                        : ""}
                    </StyledTableCell>
                  ) : (
                    <></>
                  )}

                  <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                    {Details?.collatral_amount}
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                    {formattedDate}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
      ) : null}
    </TableContainer>
  );
}
