import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  loanUserDecision,
  InitiateDebitFunction,
  GetLoanDetails,
} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgressWithLabel from "../../Common/CircularProgressWithLabel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import Alert from "@mui/material/Alert";
import { Document, Page, pdfjs } from "react-pdf";
import DownloadIcon from "@mui/icons-material/Download";
import { ESignDialogView } from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { TextField } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SessionModal from "./../../Common/SessionModel";

const steps = [
  {
    label: "Application Form",
    description: ``,
  },
  {
    label: "Sanction Letter",
    description: "",
  },
  {
    label: "Loan Agreement",
    description: ``,
  },
  {
    label: "eMandate",
    description: ``,
  },
  {
    label: "Confirm & Close",
    description: ``,
  },
];

export default function InitateDebit(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedLoanId = props.loanID;
  const [activeStep, setActiveStep] = React.useState(4);
  const [showAlert, setShowAlert] = useState(false);

  const esiginDialog = useSelector(
    (state) => state.BasicDetailsReducers.esiginDialog
  );

  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    navigate("/MyLoan");
    // dispatch(GetLoanDetails(handleLogoutButton));
    // dispatch(ESignDialogView(!esiginDialog));
    // dispatch(
    //   loanUserDecision(selectedLoanId, "APPROVED", "", handleLogoutButton)
    // );
  };

  // initiate debit API Response

  const initiateDebitLoading = useSelector(
    (state) => state.BasicDetailsReducers.initiateDebitLoading
  );
  const initiateDebitSuccess = useSelector(
    (state) => state.BasicDetailsReducers.initiateDebitSuccess
  );
  const initiateDebitResponse = useSelector(
    (state) => state.BasicDetailsReducers.initiateDebitResponse
  );
  const initiateDebitFail = useSelector(
    (state) => state.BasicDetailsReducers.initiateDebitFail
  );

  const handleDebit = () => {
    dispatch(InitiateDebitFunction(selectedLoanId, handleLogoutButton));
  };
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };
  const handleCloseErrorModal1 = () => {
    setShowAlert(false);
    navigate("/login");
  };
  return (
    <Grid container>
      <div>
        {/* Your component content */}
        <SessionModal isOpen={showAlert} onClose={handleCloseErrorModal1} />
      </div>
      <Grid item xs={12} md={6} sx={{ p: 5 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 4 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>

              {activeStep === 4 && index === 4 ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      m: 5,
                      mt: 2,
                      mb: 3,
                      ml: 0,
                    }}
                  >
                    <Alert severity="info">Great! All steps completed.</Alert>
                  </Grid>
                </>
              ) : null}

              <StepContent>
                {/* <Grid container>
                  <Grid item xs={12} sx={{ ml: -4, mt: 2 }}>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ textTransform: "capitalize" }}
                    >
                      Confirm & Finish
                    </Button>
                  </Grid>
                </Grid> */}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
}
