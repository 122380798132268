import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme } from "./Common/Theme";
import BasicDetails from "./Components/BasicDetails/Index";
import UploadDocument from "./Components/UploadDocument/Index";
import LoanCofirmation from "./Components/LoanConfirmation/Index";
import MyLoan from "./Components/MyLoan/Index";
import CoverPage from "./Components/LoginSignUp/coverPage";
import SplashScreen from "./Components/LoginSignUp/splashScreen";
import LoginSignup from "./Components/LoginSignUp/loginSignup";
import Registration from "./Components/LoginSignUp/register";
import RegistrationSms from "./Components/LoginSignUp/registerSms"
function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <Router>
        <Routes>
          <Route path="/" element={<LoginSignup />} />
          <Route path="/Login" element={<LoginSignup />} />
          <Route path="/BasicDetails" element={<BasicDetails />} />
          <Route path="/UploadDocument" element={<UploadDocument />} />
          <Route path="/LoanCofirmation" element={<LoanCofirmation />} />
          <Route path="/MyLoan" element={<MyLoan />} />
          <Route path="/digitalLoans" element={<Registration />} />
          <Route path="/digitalLoans/:channel" element={<RegistrationSms />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
