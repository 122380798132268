import React from 'react';
import TextField from '@mui/material/TextField';

function ImageTextField({ base64Image }) {
  // Convert Base64 string to image source
  const imageSrc = `data:image/png;base64,${base64Image}`;

  return (
    <>
    <img src={imageSrc} alt="Image" style={{ height: 'auto', width: '50%', marginBottom: "0.5rem" }}/>
    <br/>
    <TextField
      label="Enter Captcha above"
      type="text"
      style={{height: "auto", width: "50%", marginBottom: "1rem"}}
    />
    </>
  );
}

export default ImageTextField;
