import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  GenerateLoanAgreement,
  ESign,
  ESignCheck,
  ESignCheckClearData,
  ESignClearData,
  GenerateLoanAgreementClearData,
  EStamp,
  loanUserDecision,
  Enach,
  EnachStatus,
  InitiateDebitFunction,
  GenerateLoanApplication,
  GetSanctionLetter,
} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgressWithLabel from "../../Common/CircularProgressWithLabel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import Alert from "@mui/material/Alert";
import { Document, Page, pdfjs } from "react-pdf";
import DownloadIcon from "@mui/icons-material/Download";
import { ESignDialogView } from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { TextField } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SessionModal from "./../../Common/SessionModel";
const steps = [
  {
    label: "Genrate Application Form",
    description: ``,
  },
  {
    label: "eSign",
    description: "",
  },
  {
    label: "Sanction Letter",
    description: ``,
  },
  {
    label: "eMandate",
    description: ``,
  },
  {
    label: "Confirm & Close",
    description: ``,
  },
];

export default function LoanConfirmationScreen(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedLoanId = props.loanID;
  const [activeStep, setActiveStep] = React.useState(0);
  const [eSignInitatedFlag, seteSignInitatedFlag] = React.useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const esiginDialog = useSelector(
    (state) => state.BasicDetailsReducers.esiginDialog
  );
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };
  const handleCloseErrorModal1 = () => {
    setShowAlert(false);
    navigate("/login");
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // if (activeStep === 1) {
    //   navigate("/MyLoan");
    //   dispatch(ESignDialogView(!esiginDialog));
    //   dispatch(loanUserDecision(selectedLoanId, "APPROVED", ""));
    // }
    if (activeStep === 1) {
      // dispatch(ESignCheck());
    }
    if (activeStep === 2) {
      dispatch(EnachStatus(selectedLoanId, handleLogoutButton));
    }
    if (activeStep === 3) {
      navigate("/MyLoan");
      dispatch(ESignDialogView(!esiginDialog));
      dispatch(
        loanUserDecision(selectedLoanId, "APPROVED", "", handleLogoutButton)
      );
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // if (activeStep === 2) {
    //   dispatch(ESignCheckClearData());
    // }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleGenerateLoanAgreement = () => {
    // dispatch(GenerateLoanAgreement(selectedLoanId));
    dispatch(GenerateLoanApplication(selectedLoanId, handleLogoutButton));
  };

  const handleEsign = () => {
    if (generateLoanApplicationResponse) {
      if (generateLoanApplicationResponse.status == "success") {
        localStorage.setItem(
          "file_name",
          generateLoanAgreementResponse.blob_url
        );
        // dispatch(ESign(1908, generateLoanAgreementResponse.file_name));
        dispatch(
          ESign(
            selectedLoanId,
            generateLoanApplicationResponse.base64,
            handleLogoutButton
          )
        );
      } else {
        alert("Loan application not found");
      }
    }
  };
  //Estamp - API Response
  const eStampLoading = useSelector(
    (state) => state.BasicDetailsReducers.eStampLoading
  );
  const eStampSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eStampSuccess
  );
  const eStampFail = useSelector(
    (state) => state.BasicDetailsReducers.eStampFail
  );

  //Generate Application form

  const generateLoanApplicationLoading = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanApplicationLoading
  );

  const generateLoanApplicationSuccess = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanApplicationSuccess
  );

  const generateLoanApplicationResponse = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanApplicationResponse
  );

  const generateLoanApplicationFail = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanApplicationFail
  );

  //Generate Agreement API Response

  const generateLoanAgreementLoading = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementLoading
  );

  const generateLoanAgreementSuccess = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementSuccess
  );

  const generateLoanAgreementResponse = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementResponse
  );

  const generateLoanAgreementFail = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementFail
  );

  //Get Sanction Letter API Response

  const getSanctionLetterLoading = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterLoading
  );

  const getSanctionLetterSuccess = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterSuccess
  );

  const getSanctionLetterResponse = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterResponse
  );

  const getSanctionLetterFail = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFail
  );

  //Save Sanction Letter API Response

  const saveSanctionLetterLoading = useSelector(
    (state) => state.BasicDetailsReducers.saveSanctionLetterLoading
  );

  const saveSanctionLetterSuccess = useSelector(
    (state) => state.BasicDetailsReducers.saveSanctionLetterSuccess
  );

  const saveSanctionLetterResponse = useSelector(
    (state) => state.BasicDetailsReducers.saveSanctionLetterResponse
  );

  const saveSanctionLetterFail = useSelector(
    (state) => state.BasicDetailsReducers.saveSanctionLetterFail
  );

  //Get Sanction Letter Status API Response

  const getSanctionLetterFlagLoading = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFlagLoading
  );

  const getSanctionLetterFlagSuccess = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFlagSuccess
  );

  const getSanctionLetterFlagResponse = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFlagResponse
  );

  const getSanctionLetterFlagFail = useSelector(
    (state) => state.BasicDetailsReducers.getSanctionLetterFlagFail
  );

  // useEffect(() => {
  //   initiateEsign();
  // }, [generateLoanAgreementResponse]);

  // const initiateEsign = () => {

  //   if(generateLoanAgreementResponse) {
  //         if(generateLoanAgreementResponse.status == true) {
  //           setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //           dispatch(ESign(1908,generateLoanAgreementResponse.file_name))
  //         }
  //   }
  // }

  //
  useEffect(() => {
    clearLocalStorage();
  }, []);

  const clearLocalStorage = () => {
    localStorage.removeItem("docket_id");
    localStorage.removeItem("document_id");
    localStorage.removeItem("file_name");
    dispatch(ESignCheckClearData());
    dispatch(ESignClearData());
    dispatch(GenerateLoanAgreementClearData());
  };

  const [pdfUrlView, setPdfUrlView] = React.useState();

  useEffect(() => {
    previewPDF();
  }, [generateLoanApplicationResponse]);

  const previewPDF = () => {
    if (generateLoanApplicationResponse) {
      if (generateLoanApplicationResponse.status == "success") {
        const base64Content = generateLoanApplicationResponse.base64;
        const base64Data = base64Content;
        const pdfData = atob(base64Data);
        const uint8Array = new Uint8Array(pdfData.length);
        for (let i = 0; i < pdfData.length; i++) {
          uint8Array[i] = pdfData.charCodeAt(i);
        }
        const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrlView(pdfUrl);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      }
    }
  };

  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPage) => prevPage - 1);
    }
  };

  //-- Download In Web verison

  // const handleDownload = () => {
  //   fetch(pdfUrlView)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = "Loan_agreement_document.pdf"; // Change the filename if needed
  //       document.body.appendChild(a);
  //       a.click();
  //       document.body.removeChild(a);
  //       URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => console.error("Error downloading PDF:", error));
  // };

  //--------------------------------------------------------------------------------------
  const handleDownload = () => {
    fetch(pdfUrlView)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Loan_Application_Form.pdf"; // Change the filename if needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };

  //Esign API Response

  const eSignLoading = useSelector(
    (state) => state.BasicDetailsReducers.eSignLoading
  );
  const eSignResponse = useSelector(
    (state) => state.BasicDetailsReducers.eSignResponse
  );

  const eSignSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eSignSuccess
  );

  const eSignFail = useSelector(
    (state) => state.BasicDetailsReducers.eSignFail
  );

  //Esign Check

  const eSignCheckLoading = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckLoading
  );

  const eSignCheckSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckSuccess
  );

  const eSignCheckResponse = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckResponse
  );

  const eSignCheckFail = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckFail
  );

  //E Mandate
  //Auth -> it will be either a mobile number or email ->
  //Where user will get a link to initate the eMandate process

  const [auth, setAuth] = React.useState(localStorage.getItem("EmailId"));

  const handleAuthChange = (e) => {
    setAuth(e);
  };

  const handleEmandate = () => {
    dispatch(Enach(selectedLoanId, auth));
  };

  //E-Mandate/Enach - Initate API Response

  const eNachLoading = useSelector(
    (state) => state.BasicDetailsReducers.eNachLoading
  );

  const eNachResponse = useSelector(
    (state) => state.BasicDetailsReducers.eNachResponse
  );

  const eNachSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eNachSuccess
  );

  const eNachFail = useSelector(
    (state) => state.BasicDetailsReducers.eNachFail
  );

  // Enach Status
  const eNachStatusLoading = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusLoading
  );
  const eNachStatusSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusSuccess
  );
  const eNachStatusResponse = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusResponse
  );
  const eNachStatusFail = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusFail
  );

  //initiate debit

  useEffect(() => {
    initiateDebit();
  }, [eNachStatusSuccess]);

  const initiateDebit = () => {
    if (eNachStatusSuccess === true) {
      dispatch(InitiateDebitFunction(selectedLoanId, handleLogoutButton));
    }
  };

  // initiate debit API Response

  const initiateDebitLoading = useSelector(
    (state) => state.BasicDetailsReducers.initiateDebitLoading
  );
  const initiateDebitSuccess = useSelector(
    (state) => state.BasicDetailsReducers.initiateDebitSuccess
  );
  const initiateDebitResponse = useSelector(
    (state) => state.BasicDetailsReducers.initiateDebitResponse
  );
  const initiateDebitFail = useSelector(
    (state) => state.BasicDetailsReducers.initiateDebitFail
  );



  const handleSanctionLetter = () => {
    dispatch(GetSanctionLetter(selectedLoanId, handleLogoutButton));
  };

  return (
    <Grid container>
      <div>
        {/* Your component content */}
        <SessionModal isOpen={showAlert} onClose={handleCloseErrorModal1} />
      </div>
      <Grid item xs={12} md={6} sx={{ p: 5 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 3 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                {/* Based on Active Step Content Will Change */}
                {/* Active Stepm 0 ->  Generate Loan Agreement */}
                {activeStep === 0 ? (
                  <>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          m: 5,
                          ml: 0,
                          display: "flex",
                          justifyContent: "flex-start",
                          mt: 7,
                          mb: 3,
                        }}
                      >
                        <Button
                          onClick={handleGenerateLoanAgreement}
                          variant="outlined"
                          endIcon={<AssignmentIcon />}
                          sx={{ textTransform: "capitalize" }}
                        >
                          Generate Application Form
                        </Button>
                      </Grid>
                      {generateLoanApplicationLoading ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              m: 5,
                              mt: 1,
                              ml: 0,
                            }}
                          >
                            <CircularProgressWithLabel />
                          </Grid>
                        </>
                      ) : null}
                      {generateLoanApplicationSuccess ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sx={{ display: "flex", ml: 5, ml: 0 }}
                          >
                            <Alert severity="success">
                              Application form is successfully generated.
                            </Alert>
                          </Grid>
                        </>
                      ) : null}
                      {generateLoanApplicationFail ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sx={{ display: "flex", ml: 5, ml: 0 }}
                          >
                            <Alert severity="error">
                              Sorry! Failed to generate application form
                            </Alert>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </>
                ) : null}

                {/* Active Step 1 -> Esign And Estaamp */}
                {activeStep === 1 ? (
                  <>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          m: 5,
                          display: "flex",
                          justifyContent: "flex-start",
                          mt: 7,
                          mb: 3,
                          ml: 0,
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={handleEsign}
                          endIcon={<DocumentScannerIcon />}
                          sx={{ textTransform: "capitalize" }}
                        >
                          initiate process
                        </Button>
                      </Grid>
                      {eSignSuccess ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              m: 5,
                              mt: 2,
                              ml: 0,
                            }}
                          >
                            <Alert severity="info">
                              To finalize the eSign procedure for your account,
                              we have sent an email to your registered email
                              address with all the necessary instructions and a
                              link to complete the process. Kindly check your
                              inbox to find the email from our team.
                            </Alert>
                          </Grid>
                        </>
                      ) : null}
                      {eSignLoading ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              m: 5,
                              mt: 1,
                            }}
                          >
                            <CircularProgressWithLabel />
                          </Grid>
                        </>
                      ) : null}
                      {eSignFail ? (
                        <>
                          <Grid item xs={12} sx={{ display: "flex", ml: 0 }}>
                            <Alert severity="error">
                              Sorry! Failed to complete eSign
                            </Alert>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </>
                ) : null}

                {activeStep === 2 ? (
                  <>
                    {eSignCheckLoading ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 1,
                          }}
                        >
                          <CircularProgressWithLabel />
                        </Grid>
                      </>
                    ) : null}
                    {eSignCheckFail ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 2,
                            mb: 3,
                            ml: 0,
                          }}
                        >
                          <Alert severity="error">
                            Sorry! Kindly check your inbox to find the email
                            from our team and complete the eSign.
                          </Alert>
                        </Grid>
                      </>
                    ) : null}
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          m: 5,
                          ml: 0,
                          display: "flex",
                          justifyContent: "flex-start",
                          mt: 7,
                          mb: 3,
                        }}
                      >
                        <Button
                          onClick={handleSanctionLetter}
                          variant="outlined"
                          endIcon={<AssignmentIcon />}
                          sx={{ textTransform: "capitalize" }}
                        >
                          Genrate Sanction Letter
                        </Button>
                      </Grid>
                    </Grid>
                    {getSanctionLetterLoading ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 1,
                          }}
                        >
                          <CircularProgressWithLabel />
                        </Grid>
                      </>
                    ) : null}

                    {getSanctionLetterSuccess ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 2,
                            mb: 3,
                            ml: 0,
                          }}
                        >
                          <Alert severity="success">
                            Completed! Please review the sanction letter
                          </Alert>
                        </Grid>
                      </>
                    ) : null}

                    {getSanctionLetterFail ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 2,
                            mb: 3,
                            ml: 0,
                          }}
                        >
                          <Alert severity="error">
                            Sorry! Faile to generate sanction letter
                          </Alert>
                        </Grid>
                      </>
                    ) : null}
                  </>
                ) : null}

                {/* Active Step 1 -> Esign Confirmation */}
                {activeStep === 3 ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        m: 5,
                        mt: 3,
                        mb: 2,
                        ml: 0,
                      }}
                    >
                      <Typography sx={{ fontWeight: 900 }}>
                        Kindly share your mobile number or email, in order to
                        proceed with your request!
                      </Typography>
                    </Grid>
                    <Grid container spacing={2} sx={{ display: "flex", mt: 2 }}>
                      <Grid item xs={6} sx={{}}>
                        <TextField
                          type="text"
                          size="small"
                          name="auth"
                          fullWidth
                          disabled={true}
                          id="auth"
                          label="Mobile Number / Email Address"
                          value={auth}
                          onChange={(e) => handleAuthChange(e.target.value)}
                        ></TextField>
                      </Grid>
                      <Grid item xs={6} sx={{}}>
                        <Button
                          variant="outlined"
                          onClick={handleEmandate}
                          endIcon={<AccountBalanceIcon />}
                          sx={{ textTransform: "capitalize", p: 0.87 }}
                        >
                          Initiate Request
                        </Button>
                      </Grid>
                    </Grid>

                    {eNachLoading ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            m: 5,
                            mt: 3,
                            ml: 0,
                          }}
                        >
                          <CircularProgressWithLabel />
                        </Grid>
                      </>
                    ) : null}
                    {eNachFail ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 2,
                            mb: 3,
                            ml: 0,
                          }}
                        >
                          <Alert severity="error">
                            Sorry! Failed to process the request!
                          </Alert>
                        </Grid>
                      </>
                    ) : null}
                    {eNachSuccess ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 2,
                            mb: 3,
                            ml: 0,
                          }}
                        >
                          <Alert severity="info">
                            {eNachResponse
                              ? eNachResponse.message
                              : "Sorry! Failed to process the request!"}
                          </Alert>
                        </Grid>
                      </>
                    ) : null}

                    {/* {eSignCheckSuccess ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 3,
                            mb: 2,
                            ml: 0,
                          }}
                        >
                          <Alert severity="success">
                            Thank you! All the steps are completed.
                          </Alert>
                        </Grid>
                      </>
                    ) : null} */}
                  </>
                ) : null}
                {activeStep === 4 ? (
                  <>
                    {eNachStatusLoading ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 1,
                            ml: 0,
                          }}
                        >
                          <CircularProgressWithLabel />
                        </Grid>
                      </>
                    ) : null}
                    {eNachStatusFail ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 2,
                            mb: 3,
                            ml: 0,
                          }}
                        >
                          <Alert severity="error">
                            Sorry! Failed to confirm the eMandate details!
                          </Alert>
                        </Grid>
                      </>
                    ) : null}
                    {eNachStatusSuccess ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 2,
                            mb: 3,
                            ml: 0,
                          }}
                        >
                          <Alert severity="info">
                            Great! Almost done. We are initiating the debit
                            procedure.
                          </Alert>
                        </Grid>
                      </>
                    ) : null}
                    {initiateDebitLoading ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 1,
                            ml: 0,
                          }}
                        >
                          <CircularProgressWithLabel />
                        </Grid>
                      </>
                    ) : null}
                    {initiateDebitFail ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 2,
                            mb: 3,
                            ml: 0,
                          }}
                        >
                          <Alert severity="error">
                            Sorry! Failed to confirm the debit details!
                          </Alert>
                        </Grid>
                      </>
                    ) : null}
                    {initiateDebitSuccess ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 2,
                            mb: 3,
                            ml: 0,
                          }}
                        >
                          <Alert severity="info">
                            Great! All step completed!
                          </Alert>
                        </Grid>
                      </>
                    ) : null}
                  </>
                ) : null}

                <Box sx={{ mb: 2 }}>
                  <Grid container>
                    <Grid item xs={12} sx={{ m: 5, ml: 1, mr: 1 }}>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        disabled={
                          Object.keys(generateLoanApplicationResponse)
                            .length === 0
                        }
                        sx={{ mt: 1, mr: 1, textTransform: "capitalize" }}
                      >
                        {index === steps.length - 1 ? "Finish" : "Continue"}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1, textTransform: "capitalize" }}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid>
      {Object.keys(generateLoanApplicationResponse).length &&
      activeStep === 0 ? (
        <>
          <Grid item xs={12} md={6} sx={{ border: "1px solid #000000", mt: 5 }}>
            <div style={{ padding: "10px", height: "550px", overflow: "auto" }}>
              <div style={{ height: "500px" }}>
                <Document
                  file={pdfUrlView}
                  onLoadSuccess={onDocumentLoadSuccess}
                  options={{ workerSrc: "/pdf.worker.js" }}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
              </div>
              {numPages && (
                <p>
                  Page {pageNumber} of {numPages}
                </p>
              )}
            </div>
            {numPages && (
              <Grid container>
                <Grid item xs={12} md={9}>
                  <div>
                    <Button
                      sx={{ textTransform: "capitalize" }}
                      onClick={handlePrevPage}
                      disabled={pageNumber === 1}
                    >
                      Previous Page
                    </Button>
                    <Button
                      sx={{ textTransform: "capitalize" }}
                      onClick={handleNextPage}
                      disabled={pageNumber === numPages}
                    >
                      Next Page
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    endIcon={<DownloadIcon />}
                    onClick={handleDownload}
                    sx={{ textTransform: "capitalize" }}
                  >
                    Download Application
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}
