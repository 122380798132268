import React, { useState } from "react";

// redux imports
import { useSelector, useDispatch } from "react-redux";
import { ChatMessagesClearData, UserQueryClearData } from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";

//mui import
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ErrorAlert({ jwtToken }) {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const userQueryError = useSelector((state) => state.BasicDetailsReducers.userQueryError);
  const chatMessagesError = useSelector((state) => state.BasicDetailsReducers.chatMessagesError);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // sessionStorage.removeItem("invalid_jwtToken");
    if (chatMessagesError === true) {
      dispatch(ChatMessagesClearData());
    }
    if (userQueryError === true) {
      dispatch(UserQueryClearData());
    }
    setOpen(false);
  };
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {/* {jwtToken === "invalid" ? "Session has expired, Please log in again." : "Internal Server Error, Please Try again."} */}
          {(userQueryError === true || chatMessagesError === true) && "Internal Server Error, Please Try again."}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default ErrorAlert;
