import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { ConfirmAllDilaog } from "../BasicDetailsActions/BasicDetailsActions";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { TopbarViewSteps } from "../BasicDetailsActions/BasicDetailsActions";

export default function DialogView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const confirmDailog = useSelector(
    (state) => state.BasicDetailsReducers.confirmDailog
  );

  const stepCount = useSelector(
    (state) => state.BasicDetailsReducers.stepCount
  );

  const handleNext = () => {
    let count = stepCount + 1;
    dispatch(ConfirmAllDilaog(!confirmDailog));
    dispatch(TopbarViewSteps(count));
    navigate("/UploadDocument");
  };
  const handleClose = () => {
    dispatch(ConfirmAllDilaog(!confirmDailog));
  };

  const saveAadharLoading = useSelector(
    (state) => state.BasicDetailsReducers.saveAadharLoading
  );
  const safLoading = useSelector(
    (state) => state.BasicDetailsReducers.safLoading
  );
  const saveAadharSuccess = useSelector(
    (state) => state.BasicDetailsReducers.saveAadharSuccess
  );
  const employmentType = useSelector(
    (state) => state.BasicDetailsReducers.employmentType
  );

  const messsage = saveAadharSuccess ? saveAadharSuccess.message : "";

  const saveAadharFail = useSelector(
    (state) => state.BasicDetailsReducers.saveAadharFail
  );

  return (
    <div>
      {employmentType != 1 && employmentType != 2 ? (
        <Dialog
          open={confirmDailog}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{ color: "#0093fb" }} id="alert-dialog-title">
            {"Confirmation Message"}
          </DialogTitle>
          <DialogContent>
            {saveAadharLoading ? (
              <>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              </>
            ) : null}
            {saveAadharSuccess && !saveAadharLoading ? (
              <>
                {messsage == "The company director data saved successfully." ? (
                  <>
                    <DialogContentText
                      sx={{ fontWeight: 900 }}
                      id="alert-dialog-description"
                    >
                      Thank you for saving your basic informations. We have
                      received your details and can confirm that your
                      information has been successfully saved in our system.
                    </DialogContentText>
                  </>
                ) : (
                  <>
                    <DialogContentText id="alert-dialog-description">
                      {/* We apologize for the inconvenience caused. We regret to
                      inform you that we were unable to save your basic user
                      information due to some technical difficulties. Try Again */}
                      Thank you for saving your basic informations. We have
                      received your details and can confirm that your
                      information has been successfully saved in our system.
                    </DialogContentText>
                  </>
                )}
              </>
            ) : null}
          </DialogContent>
          <DialogActions>
            {saveAadharSuccess && !saveAadharLoading ? (
              <>
                <Button
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleNext}
                  autoFocus
                >
                  Next Step
                </Button>
              </>
            ) : null}
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={confirmDailog}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{ color: "#0093fb" }} id="alert-dialog-title">
            {"Confirmation Message"}
          </DialogTitle>
          <DialogContent>
            {safLoading ? (
              <>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              </>
            ) : null}
            {!safLoading ? (
              <>
                <DialogContentText
                  sx={{ fontWeight: 900 }}
                  id="alert-dialog-description"
                >
                  Thank you for saving your basic informations. We have received
                  your details and can confirm that your information has been
                  successfully saved in our system.
                </DialogContentText>
              </>
            ) : null}
          </DialogContent>
          <DialogActions>
            {!safLoading ? (
              <>
                <Button
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleNext}
                  autoFocus
                >
                  Next Step
                </Button>
              </>
            ) : null}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
