import React from 'react';
import TextField from '@mui/material/TextField';

function ImageTextField({ base64Image }) {
  // Convert Base64 string to image source
  const imageSrc = `data:image/png;base64,${base64Image}`;

  return (
    <TextField
      label="Captcha"
      type="text"
      value={imageSrc}
      InputProps={{
        startAdornment: (
          <img
            src={imageSrc}
            alt="Image"
            style={{ height: 'auto', width: '100%' }}
          />
        ),
      }}
      fullWidth
      disabled
    />
  );
}

export default ImageTextField;
