import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Typography, Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import pic from "./../Assets/errorMessage.svg";
import { useSelector, useDispatch } from "react-redux";
import { TopbarViewSteps } from "../Components/BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { useNavigate } from "react-router-dom";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ErrorModel = ({ isOpen, onClose, title, message }) => {
  let stepCount = useSelector((state) => state.BasicDetailsReducers.stepCount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    
  };
  return (
    <BootstrapDialog
      className="errorModal"
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <DialogContent>
        <img
          src={pic}
          style={{
            height: "25vh",
            width: "97%",
            margin: "2%",
          }}
        />
        <Typography
          sx={{ mr: 4, flex: 1 }}
          component="div"
          style={{ fontWeight: "800", textAlign: "center" }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ mr: 4, flex: 1 }}
          component="div"
          style={{ textAlign: "center" }}
        >
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className="download-button">
          Ok
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ErrorModel;
