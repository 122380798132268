import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import corestratLogo from "../../Assets/corestratWhite.png";
import "./SplashScreen.css";
import Typography from "@mui/material/Typography";
const SplashScreen = () => {
  const [showSplash, setShowSplash] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    // After 5 seconds, hide the splash screen and navigate to another page
    const timer = setTimeout(() => {
      setShowSplash(false);
      navigate("/login"); // Replace "/other-page" with the desired URL
    }, 5000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {showSplash && (
        <div className="splash-screen">
          <img src={corestratLogo} alt="Corestrat Logo" />
        </div>
      )}

      {!showSplash && (
        <div>
          {/* Render your main content here */}
          <h1>Welcome to the App!</h1>
          {/* ... */}
        </div>
      )}
    </div>
  );
};

export default SplashScreen;
